import img_0 from "./images/monitor.png";
export default {
  v: "5.7.4",
  fr: 60,
  ip: 0,
  op: 304,
  w: 900,
  h: 900,
  nm: "Graph Section",
  ddd: 0,
  assets: [
    { id: "image_0", w: 588, h: 467, u: "", p: img_0, e: 0 },
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: "Graph-details",
          refId: "comp_1",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 127,
                  s: [0],
                },
                { t: 142, s: [100] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 127,
                  s: [450, 607.5, 0],
                  to: [0, -26.25, 0],
                  ti: [0, 26.25, 0],
                },
                { t: 240, s: [450, 450, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [450, 450, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          w: 900,
          h: 900,
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 5,
          nm: "Year",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [364.211, 213.879, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-35.789, -12.621, 0], ix: 1, l: 2 },
            s: { a: 0, k: [34.773, 34.773, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 36,
                    f: "Roboto-Regular",
                    t: "Year",
                    j: 1,
                    tr: 0,
                    lh: 43.2,
                    ls: 0,
                    fc: [0.53, 0.53, 0.53],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "dropdown outline",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [450, 450, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [68.18, 30.156], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.54934129902, 0.54934129902, 0.54934129902, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0.5, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-84.91, -235.922], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "dropdown icon",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [450, 450, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-58.5, -236],
                        [-65.125, -236],
                        [-61.5, -232.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.519347426471, 0.519347426471, 0.519347426471, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "Green Dot",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 127,
                  s: [318.329, 390.954, 0],
                  to: [0, -15, 0],
                  ti: [0, 15, 0],
                },
                { t: 240, s: [318.329, 300.954, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [-131.046, -149.046, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [7.908, 7.908], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0, 0.830841064453, 0.614562988281, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-131.046, -149.046], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "Green Graph",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [450, 450, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 127,
                        s: [
                          {
                            i: [
                              [-28.5, 0],
                              [-23.5, 0],
                              [0, 1.5],
                            ],
                            o: [
                              [28.5, 0],
                              [23.5, 0],
                              [0, -1.5],
                            ],
                            v: [
                              [-281.5, -58.5],
                              [-132.5, -58.5],
                              [-70.5, -58.5],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        t: 240,
                        s: [
                          {
                            i: [
                              [-28.5, 0],
                              [-44, 0],
                              [0, 1.5],
                            ],
                            o: [
                              [57.5, 0],
                              [36.5, 0],
                              [0, -1.5],
                            ],
                            v: [
                              [-281.5, -58.5],
                              [-132.5, -148.5],
                              [-70.5, -58.5],
                            ],
                            c: false,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0.821624755859, 0.607757568359, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  bm: 0,
                  g: {
                    p: 3,
                    k: {
                      a: 0,
                      k: [
                        0, 0.778, 0.996, 0.94, 0.684, 0.389, 0.912, 0.774, 1, 0,
                        0.828, 0.609, 0, 1, 0.487, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [-132.379, -149.617], ix: 5 },
                  e: { a: 0, k: [-133.254, -57.395], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "Blue Dot",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.167, y: 0.171 },
                  t: 127,
                  s: [318.329, 382.454, 0],
                  to: [0, -27.083, 0],
                  ti: [0, 27.083, 0],
                },
                { t: 240, s: [318.329, 219.954, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [-131.046, -149.046, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [7.908, 7.908], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.549011230469, 0.788238525391, 0.917633056641, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-131.046, -149.046], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: "Blue Graph",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [274, 382.833, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-176, -58.167, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 127,
                        s: [
                          {
                            i: [
                              [-28.5, 0],
                              [-23.5, 0],
                              [0, 1.5],
                            ],
                            o: [
                              [28.5, 0],
                              [23.5, 0],
                              [0, -1.5],
                            ],
                            v: [
                              [-281.5, -58.5],
                              [-132.5, -58.5],
                              [-70.5, -58.5],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        t: 240,
                        s: [
                          {
                            i: [
                              [-28.5, 0],
                              [-44, 0],
                              [0, 1.5],
                            ],
                            o: [
                              [57.5, 0],
                              [36.5, 0],
                              [0, -1.5],
                            ],
                            v: [
                              [-281.5, -58.5],
                              [-132.5, -221],
                              [-70.5, -58.5],
                            ],
                            c: false,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.548217773438, 0.787109375, 0.916320800781, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "gf",
                  o: { a: 0, k: 100, ix: 10 },
                  r: 1,
                  bm: 0,
                  g: {
                    p: 3,
                    k: {
                      a: 0,
                      k: [
                        0, 0.778, 0.996, 0.94, 0.684, 0.389, 0.912, 0.774, 1, 0,
                        0.828, 0.609, 0, 1, 0.487, 0.5, 1, 0,
                      ],
                      ix: 9,
                    },
                  },
                  s: { a: 0, k: [-132.379, -149.617], ix: 5 },
                  e: { a: 0, k: [-133.254, -57.395], ix: 6 },
                  t: 1,
                  nm: "Gradient Fill 1",
                  mn: "ADBE Vector Graphic - G-Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 5,
          nm: "2021-09",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [376.041, 425.203, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-68.959, -12.797, 0], ix: 1, l: 2 },
            s: { a: 0, k: [22.444, 22.444, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 36,
                    f: "Roboto-Regular",
                    t: "2021-09",
                    j: 1,
                    tr: 0,
                    lh: 43.2,
                    ls: 0,
                    fc: [0.53, 0.53, 0.53],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 5,
          nm: "2021-08",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [318.041, 425.203, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-68.959, -12.797, 0], ix: 1, l: 2 },
            s: { a: 0, k: [22.444, 22.444, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 36,
                    f: "Roboto-Regular",
                    t: "2021-08",
                    j: 1,
                    tr: 0,
                    lh: 43.2,
                    ls: 0,
                    fc: [0.53, 0.53, 0.53],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 5,
          nm: "2021-07",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [257.041, 425.203, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-68.959, -12.797, 0], ix: 1, l: 2 },
            s: { a: 0, k: [22.444, 22.444, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 36,
                    f: "Roboto-Regular",
                    t: "2021-07",
                    j: 1,
                    tr: 0,
                    lh: 43.2,
                    ls: 0,
                    fc: [0.53, 0.53, 0.53],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 5,
          nm: "2021-06",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [198.041, 425.203, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-68.959, -12.797, 0], ix: 1, l: 2 },
            s: { a: 0, k: [22.444, 22.444, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 36,
                    f: "Roboto-Regular",
                    t: "2021-06",
                    j: 1,
                    tr: 0,
                    lh: 43.2,
                    ls: 0,
                    fc: [0.53, 0.53, 0.53],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: "line 3",
          sr: 1,
          ks: {
            o: { a: 0, k: 20, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [168.5, 215.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-281.5, -33.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-281.5, -33.5],
                        [-45, -33.5],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: "line 2",
          sr: 1,
          ks: {
            o: { a: 0, k: 20, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [168.5, 311.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-281.5, -33.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-281.5, -33.5],
                        [-45, -33.5],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: "line",
          sr: 1,
          ks: {
            o: { a: 0, k: 20, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [168.5, 416.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-281.5, -33.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-281.5, -33.5],
                        [-45, -33.5],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: "Graph Background",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [450, 450, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [294.309, 291.086], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 57, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-164.385, -133.082], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 4,
          nm: "Graph Shadow",
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [458, 458, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 29,
              nm: "Gaussian Blur",
              np: 5,
              mn: "ADBE Gaussian Blur 2",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Blurriness",
                  mn: "ADBE Gaussian Blur 2-0001",
                  ix: 1,
                  v: { a: 0, k: 20, ix: 1 },
                },
                {
                  ty: 7,
                  nm: "Blur Dimensions",
                  mn: "ADBE Gaussian Blur 2-0002",
                  ix: 2,
                  v: { a: 0, k: 1, ix: 2 },
                },
                {
                  ty: 7,
                  nm: "Repeat Edge Pixels",
                  mn: "ADBE Gaussian Blur 2-0003",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [294.309, 291.086], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 57, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-164.385, -133.082], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_1",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 5,
          nm: "Most Visited: 11201 //1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [213.711, 300.325, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-118.066, -9.623, 0], ix: 1, l: 2 },
            s: { a: 0, k: [35.354, 35.354, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 26,
                    f: "Roboto-Bold",
                    t: "Most Visited: 11201 //1",
                    j: 2,
                    tr: 0,
                    lh: 31.2,
                    ls: 0,
                    fc: [0.547, 0.785, 0.914],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 5,
          nm: "Total Visits: 19261 ",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [272.697, 278.703, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-65.777, -12.797, 0], ix: 1, l: 2 },
            s: { a: 0, k: [35.354, 35.354, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 26,
                    f: "Roboto-Bold",
                    t: "Total Visits: 19261 ",
                    j: 1,
                    tr: 0,
                    lh: 31.2,
                    ls: 0,
                    fc: [0.547, 0.785, 0.914],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 5,
          nm: "2021-10",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256.197, 250.703, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-65.777, -12.797, 0], ix: 1, l: 2 },
            s: { a: 0, k: [35.354, 35.354, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 36,
                    f: "Roboto-Bold",
                    t: "2021-08",
                    j: 1,
                    tr: 0,
                    lh: 43.2,
                    ls: 0,
                    fc: [0.547, 0.785, 0.914],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "line 4",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [212, 266.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-281.5, -33.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-281.5, -33.5],
                        [-199, -33.5],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.801639093137, 0.801639093137, 0.801639093137, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "Graph Details Background",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [450.025, 450, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [113.945, 100.736], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [0.842141544118, 0.842141544118, 0.842141544118, 1],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 1, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-195.027, -174.632], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_2",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 1",
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [450, 450, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [52.838, 41.053], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [173.919, 105.831], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 88.92], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 5,
          nm: "0 10 20 30 40 50 60 70 80 90 ",
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 120,
                  s: [648, 568.5, 0],
                  to: [0, -54, 0],
                  ti: [0, 54, 0],
                },
                { t: 208, s: [648, 244.5, 0] },
              ],
              ix: 2,
              l: 2,
            },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 36,
                    f: "Roboto-Bold",
                    t: "0\r10\r20\r30\r40\r50\r60\r70\r80\r90",
                    j: 1,
                    tr: 0,
                    lh: 36,
                    ls: 0,
                    fc: [0, 0, 0],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Resolution Rate Circle",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [639.762, 555.525, 0], ix: 2, l: 2 },
            a: { a: 0, k: [189.762, 105.525, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [125.684, 125.684], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false,
                },
                {
                  ty: "st",
                  c: { a: 0, k: [0, 0.847015380859, 0.622680664063, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 12, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [189.762, 105.525], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tm",
              s: { a: 0, k: 0, ix: 1 },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 120,
                    s: [0],
                  },
                  { t: 208, s: [90] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 5,
          nm: "%",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [648.5, 568.5, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 36,
                    f: "Roboto-Bold",
                    t: "%",
                    j: 0,
                    tr: 0,
                    lh: 43.2,
                    ls: 0,
                    fc: [0, 0, 0],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 5,
          nm: "RESOLUTION RATE",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [641.582, 459.201, 0], ix: 2, l: 2 },
            a: { a: 0, k: [174.482, -14.219, 0], ix: 1, l: 2 },
            s: { a: 0, k: [33.752, 33.752, 100], ix: 6, l: 2 },
          },
          ao: 0,
          t: {
            d: {
              k: [
                {
                  s: {
                    s: 40,
                    f: "Roboto-Bold",
                    t: "RESOLUTION RATE",
                    j: 0,
                    tr: 0,
                    lh: 48,
                    ls: 0,
                    fc: [0, 0, 0],
                  },
                  t: 0,
                },
              ],
            },
            p: {},
            m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
            a: [],
          },
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "Resolution Rate Background",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [451.309, 450, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [274.541, 222], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 56, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [190.272, 93.924], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: "Resolution Rate Background Shadow",
          sr: 1,
          ks: {
            o: { a: 0, k: 15, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [456.309, 457, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          ef: [
            {
              ty: 29,
              nm: "Gaussian Blur",
              np: 5,
              mn: "ADBE Gaussian Blur 2",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  nm: "Blurriness",
                  mn: "ADBE Gaussian Blur 2-0001",
                  ix: 1,
                  v: { a: 0, k: 20, ix: 1 },
                },
                {
                  ty: 7,
                  nm: "Blur Dimensions",
                  mn: "ADBE Gaussian Blur 2-0002",
                  ix: 2,
                  v: { a: 0, k: 1, ix: 2 },
                },
                {
                  ty: 7,
                  nm: "Repeat Edge Pixels",
                  mn: "ADBE Gaussian Blur 2-0003",
                  ix: 3,
                  v: { a: 0, k: 0, ix: 3 },
                },
              ],
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [274.541, 222], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 56, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [0, 0, 0, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [190.272, 93.924], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 328,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  fonts: {
    list: [
      { fName: "Roboto-Bold", fFamily: "Roboto", fStyle: "Bold", ascent: 75 },
      {
        fName: "Roboto-Regular",
        fFamily: "Roboto",
        fStyle: "Regular",
        ascent: 75,
      },
    ],
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: "NULL CONTROL",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [454.283, 366.002, 0], ix: 2, l: 2 },
        a: { a: 0, k: [60, 60, 0], ix: 1, l: 2 },
        s: { a: 0, k: [115, 115, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 337,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: "Graph",
      parent: 1,
      refId: "comp_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 31,
              s: [0],
            },
            { t: 69.978515625, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0.167, y: 0.167 },
              t: 19,
              s: [-394.283, -172.002, 0],
              to: [75, 52.667, 0],
              ti: [-75, -52.667, 0],
            },
            { t: 129, s: [55.717, 143.998, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [450, 450, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 900,
      h: 900,
      ip: 9,
      op: 337,
      st: 9,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: "Resolution Rate",
      parent: 1,
      refId: "comp_2",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 22,
              s: [0],
            },
            { t: 60.978515625, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0.167, y: 0.167 },
              t: 10,
              s: [404.717, 308.998, 0],
              to: [-58.167, -27.5, 0],
              ti: [58.167, 27.5, 0],
            },
            { t: 120, s: [55.717, 143.998, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [450, 450, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 900,
      h: 900,
      ip: 0,
      op: 328,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Green Square",
      parent: 1,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 22.021,
              s: [0],
            },
            { t: 61, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0.167, y: 0.371 },
              t: 10.01,
              s: [470.781, -12.879, 0],
              to: [-24.667, 15.667, 0],
              ti: [24.667, -15.667, 0],
            },
            { t: 120.12109375, s: [322.781, 81.121, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [80.064, -209.877, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "rc",
              d: 1,
              s: { a: 0, k: [20, 20], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 6, ix: 4 },
              nm: "Rectangle Path 1",
              mn: "ADBE Vector Shape - Rect",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.196078431373, 0.858823529412, 0.635294117647, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [80.064, -209.877], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Rectangle 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 328.328328328328,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "Black Square",
      parent: 1,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 22.021,
              s: [0],
            },
            { t: 61, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0.167, y: 0.353 },
              t: 10.01,
              s: [-372.781, 347.5, 0],
              to: [28.333, -26.333, 0],
              ti: [-28.333, 26.333, 0],
            },
            { t: 120.12109375, s: [-202.781, 189.5, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [66.502, -213.498, 0], ix: 1, l: 2 },
        s: { a: 0, k: [130, 130, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "rc",
              d: 1,
              s: { a: 0, k: [20, 20], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 6, ix: 4 },
              nm: "Rectangle Path 1",
              mn: "ADBE Vector Shape - Rect",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.192156862745, 0.196078431373, 0.2, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [66.502, -213.498], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Rectangle 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 328.328328328328,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Red Square",
      parent: 1,
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 22.021,
              s: [0],
            },
            { t: 61, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0.167, y: 0.353 },
              t: 10.01,
              s: [242.219, -255.5, 0],
              to: [-20, 31, 0],
              ti: [20, -31, 0],
            },
            { t: 120.12109375, s: [122.219, -69.5, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [66.502, -213.498, 0], ix: 1, l: 2 },
        s: { a: 0, k: [80, 80, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "rc",
              d: 1,
              s: { a: 0, k: [20, 20], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 6, ix: 4 },
              nm: "Rectangle Path 1",
              mn: "ADBE Vector Shape - Rect",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.901960784314, 0.388235294118, 0.486274509804, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [66.502, -213.498], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Rectangle 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 328.328328328328,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 2,
      nm: "monitor.png",
      cl: "png",
      parent: 1,
      refId: "image_0",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 10.01,
              s: [0],
            },
            { t: 61, s: [100] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [55.717, 143.998, 0], ix: 2, l: 2 },
        a: { a: 0, k: [294, 233.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [78, 78, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 328.328328328328,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 1,
      nm: "White Background",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [450, 450, 0], ix: 2, l: 2 },
        a: { a: 0, k: [450, 450, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      sw: 900,
      sh: 900,
      sc: "transparent",
      ip: 0,
      op: 328.328328328328,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  chars: [
    {
      ch: "R",
      size: 40,
      style: "Bold",
      w: 63.82,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.1, 3.239],
                      [0, 4.851],
                      [4.557, 3.744],
                      [8.398, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.264, -1.92],
                      [2.1, -3.239],
                      [0, -6.836],
                      [-4.558, -3.743],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [45.996, 0],
                      [61.719, 0],
                      [61.719, -0.684],
                      [46.338, -29.736],
                      [55.884, -37.476],
                      [59.033, -49.609],
                      [52.197, -65.479],
                      [32.764, -71.094],
                      [6.348, -71.094],
                      [6.348, 0],
                      [20.996, 0],
                      [20.996, -26.025],
                      [32.666, -26.025],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [-1.904, -1.92],
                      [0, -3.352],
                      [2.018, -1.871],
                      [3.678, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [3.938, 0],
                      [1.904, 1.921],
                      [0, 3.288],
                      [-2.019, 1.872],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [20.996, -59.229],
                      [32.764, -59.229],
                      [41.528, -56.348],
                      [44.385, -48.438],
                      [41.357, -40.698],
                      [32.812, -37.891],
                      [20.996, -37.891],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "R",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "E",
      size: 40,
      style: "Bold",
      w: 56.25,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [49.121, -42.285],
                      [20.996, -42.285],
                      [20.996, -59.229],
                      [53.906, -59.229],
                      [53.906, -71.094],
                      [6.348, -71.094],
                      [6.348, 0],
                      [54.004, 0],
                      [54.004, -11.768],
                      [20.996, -11.768],
                      [20.996, -30.908],
                      [49.121, -30.908],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "E",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "E",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "S",
      size: 40,
      style: "Bold",
      w: 61.47,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.953, -1.416],
                      [3.483, 0],
                      [0, 7.845],
                      [0, 0],
                      [-2.361, -3.483],
                      [-4.492, -2.002],
                      [-5.469, 0],
                      [-4.655, 3.5],
                      [0, 6.152],
                      [3.809, 3.711],
                      [8.333, 2.507],
                      [2.327, 1.562],
                      [0, 2.312],
                      [-1.986, 1.514],
                      [-3.581, 0],
                      [-2.051, -1.807],
                      [0, -3.255],
                      [0, 0],
                      [2.197, 3.353],
                      [3.971, 1.839],
                      [5.078, 0],
                      [4.053, -1.692],
                      [2.164, -3.011],
                      [0, -3.841],
                      [-8.073, -4.362],
                      [-5.078, -1.643],
                      [-1.953, -1.481],
                      [0, -2.766],
                    ],
                    o: [
                      [-1.953, 1.416],
                      [-9.375, 0],
                      [0, 0],
                      [0, 4.59],
                      [2.36, 3.484],
                      [4.492, 2.002],
                      [7.877, 0],
                      [4.655, -3.499],
                      [0, -5.534],
                      [-3.809, -3.711],
                      [-4.525, -1.367],
                      [-2.328, -1.562],
                      [0, -2.473],
                      [1.985, -1.514],
                      [3.711, 0],
                      [2.051, 1.807],
                      [0, 0],
                      [0, -4.264],
                      [-2.197, -3.352],
                      [-3.972, -1.839],
                      [-5.046, 0],
                      [-4.053, 1.693],
                      [-2.165, 3.011],
                      [0, 7.39],
                      [2.962, 1.595],
                      [5.078, 1.644],
                      [1.953, 1.482],
                      [0, 2.539],
                    ],
                    v: [
                      [40.283, -12.72],
                      [32.129, -10.596],
                      [18.066, -22.363],
                      [3.369, -22.363],
                      [6.909, -10.254],
                      [17.188, -2.026],
                      [32.129, 0.977],
                      [50.928, -4.272],
                      [57.91, -18.75],
                      [52.197, -32.617],
                      [33.984, -41.943],
                      [23.706, -46.338],
                      [20.215, -52.148],
                      [23.193, -58.13],
                      [31.543, -60.4],
                      [40.186, -57.69],
                      [43.262, -50.098],
                      [57.91, -50.098],
                      [54.614, -61.523],
                      [45.361, -69.312],
                      [31.787, -72.07],
                      [18.14, -69.531],
                      [8.813, -62.476],
                      [5.566, -52.197],
                      [17.676, -34.57],
                      [29.736, -29.712],
                      [40.283, -25.024],
                      [43.213, -18.652],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "S",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "S",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "O",
      size: 40,
      style: "Bold",
      w: 69.04,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [2.522, 5.322],
                      [4.606, 2.849],
                      [5.924, 0],
                      [4.606, -2.848],
                      [2.522, -5.322],
                      [0, -6.998],
                      [0, 0],
                      [-2.539, -5.257],
                      [-4.623, -2.832],
                      [-5.892, 0],
                      [-4.607, 2.865],
                      [-2.474, 5.273],
                      [0, 6.999],
                    ],
                    o: [
                      [0, -6.966],
                      [-2.523, -5.322],
                      [-4.607, -2.848],
                      [-5.925, 0],
                      [-4.607, 2.849],
                      [-2.523, 5.322],
                      [0, 0],
                      [0.032, 6.836],
                      [2.539, 5.257],
                      [4.622, 2.832],
                      [5.957, 0],
                      [4.606, -2.864],
                      [2.473, -5.273],
                      [0, 0],
                    ],
                    v: [
                      [64.746, -37.109],
                      [60.962, -55.542],
                      [50.269, -67.798],
                      [34.473, -72.07],
                      [18.677, -67.798],
                      [7.983, -55.542],
                      [4.199, -37.061],
                      [4.199, -33.545],
                      [8.057, -15.405],
                      [18.799, -3.271],
                      [34.57, 0.977],
                      [50.415, -3.32],
                      [61.035, -15.527],
                      [64.746, -33.936],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "O",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [2.637, -3.857],
                      [4.915, 0],
                      [2.669, 4.004],
                      [0, 7.259],
                      [0, 0],
                      [-2.67, 3.825],
                      [-4.916, 0],
                      [-2.67, -3.873],
                      [0, -7.454],
                    ],
                    o: [
                      [-0.033, 7.39],
                      [-2.637, 3.857],
                      [-5.013, 0],
                      [-2.67, -4.004],
                      [0, 0],
                      [0.032, -7.389],
                      [2.669, -3.825],
                      [4.948, 0],
                      [2.669, 3.874],
                      [0, 0],
                    ],
                    v: [
                      [49.902, -33.691],
                      [45.898, -16.821],
                      [34.57, -11.035],
                      [23.047, -17.041],
                      [19.043, -33.936],
                      [19.043, -37.402],
                      [23.096, -54.224],
                      [34.473, -59.961],
                      [45.898, -54.15],
                      [49.902, -37.158],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "O",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "O",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "L",
      size: 40,
      style: "Bold",
      w: 54.15,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [20.996, -71.094],
                      [6.348, -71.094],
                      [6.348, 0],
                      [52.1, 0],
                      [52.1, -11.768],
                      [20.996, -11.768],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "L",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "L",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "U",
      size: 40,
      style: "Bold",
      w: 65.82,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [8.235, 0],
                      [2.229, 2.132],
                      [0, 4.655],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-4.883, -4.395],
                      [-8.301, 0],
                      [-4.867, 4.525],
                      [0, 7.78],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-0.13, 8.659],
                      [-3.939, 0],
                      [-2.23, -2.132],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.098, 7.683],
                      [4.883, 4.395],
                      [8.43, 0],
                      [4.866, -4.524],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [45.459, -71.094],
                      [45.459, -23.779],
                      [32.91, -10.791],
                      [23.657, -13.989],
                      [20.312, -24.17],
                      [20.312, -71.094],
                      [5.664, -71.094],
                      [5.664, -23.73],
                      [13.135, -5.615],
                      [32.91, 0.977],
                      [52.856, -5.811],
                      [60.156, -24.268],
                      [60.156, -71.094],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "U",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "U",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "T",
      size: 40,
      style: "Bold",
      w: 62.65,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [59.863, -71.094],
                      [1.953, -71.094],
                      [1.953, -59.229],
                      [23.438, -59.229],
                      [23.438, 0],
                      [38.086, 0],
                      [38.086, -59.229],
                      [59.863, -59.229],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "T",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "T",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "I",
      size: 40,
      style: "Bold",
      w: 29.15,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [21.924, -71.094],
                      [7.275, -71.094],
                      [7.275, 0],
                      [21.924, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "I",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "I",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "N",
      size: 40,
      style: "Bold",
      w: 70.61,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [64.16, -71.094],
                      [49.561, -71.094],
                      [49.561, -24.219],
                      [20.996, -71.094],
                      [6.348, -71.094],
                      [6.348, 0],
                      [20.996, 0],
                      [20.996, -46.777],
                      [49.512, 0],
                      [64.16, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "N",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "N",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    { ch: " ", size: 40, style: "Bold", w: 24.9, data: {}, fFamily: "Roboto" },
    {
      ch: "A",
      size: 40,
      style: "Bold",
      w: 67.29,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [51.416, 0],
                      [66.992, 0],
                      [40.381, -71.094],
                      [26.807, -71.094],
                      [0.342, 0],
                      [15.918, 0],
                      [20.801, -14.648],
                      [46.484, -14.648],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [33.594, -53.125],
                      [42.529, -26.514],
                      [24.756, -26.514],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "A",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "A",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "%",
      size: 36,
      style: "Bold",
      w: 73.83,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-2.849, -2.686],
                      [-4.623, 0],
                      [-2.832, 2.67],
                      [0, 4.395],
                      [0, 0],
                      [2.832, 2.686],
                      [4.655, 0],
                      [2.832, -2.718],
                      [0, -4.362],
                    ],
                    o: [
                      [0, 4.558],
                      [2.848, 2.686],
                      [4.59, 0],
                      [2.832, -2.669],
                      [0, 0],
                      [0, -4.59],
                      [-2.832, -2.686],
                      [-4.59, 0],
                      [-2.832, 2.718],
                      [0, 0],
                    ],
                    v: [
                      [4.639, -53.955],
                      [8.911, -43.091],
                      [20.117, -39.062],
                      [31.25, -43.066],
                      [35.498, -53.662],
                      [35.498, -57.178],
                      [31.25, -68.091],
                      [20.02, -72.119],
                      [8.887, -68.042],
                      [4.639, -57.422],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "%",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-1.107, 1.195],
                      [-1.791, 0],
                      [-1.074, -1.212],
                      [0, -1.964],
                      [0, 0],
                      [1.074, -1.212],
                      [1.823, 0],
                      [1.123, 1.195],
                      [0, 1.965],
                    ],
                    o: [
                      [0, -2.128],
                      [1.106, -1.195],
                      [1.888, 0],
                      [1.074, 1.212],
                      [0, 0],
                      [0, 2.03],
                      [-1.074, 1.212],
                      [-1.823, 0],
                      [-1.123, -1.195],
                      [0, 0],
                    ],
                    v: [
                      [14.014, -57.188],
                      [15.674, -62.172],
                      [20.02, -63.965],
                      [24.463, -62.148],
                      [26.074, -57.384],
                      [26.074, -53.75],
                      [24.463, -48.888],
                      [20.117, -47.07],
                      [15.698, -48.862],
                      [14.014, -53.602],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "%",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 2,
                ty: "sh",
                ix: 3,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-2.832, -2.702],
                      [-4.688, 0],
                      [-2.8, 2.686],
                      [0, 4.362],
                      [0, 0],
                      [2.848, 2.654],
                      [4.622, 0],
                      [2.864, -2.686],
                      [0, -4.395],
                    ],
                    o: [
                      [0, 4.46],
                      [2.832, 2.702],
                      [4.655, 0],
                      [2.799, -2.686],
                      [0, 0],
                      [0, -4.655],
                      [-2.849, -2.653],
                      [-4.558, 0],
                      [-2.865, 2.686],
                      [0, 0],
                    ],
                    v: [
                      [38.623, -13.818],
                      [42.871, -3.076],
                      [54.15, 0.977],
                      [65.332, -3.052],
                      [69.531, -13.623],
                      [69.531, -17.139],
                      [65.259, -28.101],
                      [54.053, -32.08],
                      [42.92, -28.052],
                      [38.623, -17.432],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "%",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 3,
                ty: "sh",
                ix: 4,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-1.107, 1.195],
                      [-1.823, 0],
                      [-1.107, -1.195],
                      [0, -1.964],
                      [0, 0],
                      [3.971, 0],
                      [1.172, 1.277],
                      [0, 1.801],
                    ],
                    o: [
                      [0, -2.063],
                      [1.106, -1.195],
                      [1.823, 0],
                      [1.106, 1.195],
                      [0, 0],
                      [0, 4.42],
                      [-1.758, 0],
                      [-1.172, -1.277],
                      [0, 0],
                    ],
                    v: [
                      [47.998, -17.246],
                      [49.658, -22.133],
                      [54.053, -23.926],
                      [58.447, -22.133],
                      [60.107, -17.394],
                      [60.107, -13.661],
                      [54.15, -7.031],
                      [49.756, -8.946],
                      [47.998, -13.563],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "%",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 4,
                ty: "sh",
                ix: 5,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [57.617, -60.889],
                      [50.732, -64.6],
                      [16.016, -9.033],
                      [22.9, -5.322],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "%",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "%",
            np: 8,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "0",
      size: 36,
      style: "Bold",
      w: 57.37,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [4.102, 5.095],
                      [7.747, 0],
                      [4.118, -5.143],
                      [0, -9.928],
                      [0, 0],
                      [-4.102, -5.11],
                      [-7.748, 0],
                      [-4.07, 5.209],
                      [0, 9.831],
                    ],
                    o: [
                      [-0.098, -9.538],
                      [-4.102, -5.094],
                      [-7.748, 0],
                      [-4.118, 5.144],
                      [0, 0],
                      [0.098, 9.538],
                      [4.102, 5.111],
                      [7.845, 0],
                      [4.069, -5.208],
                      [0, 0],
                    ],
                    v: [
                      [52.686, -42.48],
                      [46.387, -64.429],
                      [28.613, -72.07],
                      [10.815, -64.355],
                      [4.639, -41.748],
                      [4.639, -28.662],
                      [10.938, -6.689],
                      [28.711, 0.977],
                      [46.582, -6.836],
                      [52.686, -29.395],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "0",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [1.53, -2.704],
                      [3.45, 0],
                      [1.579, 2.818],
                      [0, 5.8],
                      [0, 0],
                      [-1.579, 2.558],
                      [-3.32, 0],
                      [-1.611, -2.688],
                      [0, -5.898],
                    ],
                    o: [
                      [-0.066, 5.573],
                      [-1.53, 2.705],
                      [-3.484, 0],
                      [-1.579, -2.818],
                      [0, 0],
                      [0.098, -5.441],
                      [1.579, -2.557],
                      [3.418, 0],
                      [1.611, 2.689],
                      [0, 0],
                    ],
                    v: [
                      [38.574, -26.871],
                      [36.182, -14.457],
                      [28.711, -10.4],
                      [21.118, -14.628],
                      [18.75, -27.556],
                      [18.75, -44.858],
                      [21.265, -56.857],
                      [28.613, -60.693],
                      [36.157, -56.661],
                      [38.574, -43.782],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "0",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "0",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    { ch: "\r", size: 36, style: "Bold", w: 0, fFamily: "Roboto" },
    {
      ch: "1",
      size: 36,
      style: "Bold",
      w: 57.37,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [39.111, -71.191],
                      [37.598, -71.191],
                      [8.154, -60.645],
                      [8.154, -49.268],
                      [25, -54.469],
                      [25, 0],
                      [39.111, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "2",
      size: 36,
      style: "Bold",
      w: 57.37,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.823, 3.646],
                      [0, 3.516],
                      [4.053, 3.516],
                      [7.324, 0],
                      [3.776, -2.034],
                      [2.067, -3.58],
                      [0, -4.329],
                      [0, 0],
                      [-1.839, 2.197],
                      [-3.191, 0],
                      [-1.595, -1.807],
                      [0, -3.157],
                      [1.514, -2.571],
                      [3.157, -3.45],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [4.427, -4.85],
                      [1.823, -3.645],
                      [0, -6.413],
                      [-4.053, -3.516],
                      [-4.785, 0],
                      [-3.777, 2.035],
                      [-2.068, 3.581],
                      [0, 0],
                      [0, -3.58],
                      [1.839, -2.197],
                      [2.962, 0],
                      [1.595, 1.807],
                      [0, 2.312],
                      [-1.514, 2.572],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [53.564, -11.377],
                      [22.998, -11.377],
                      [39.16, -28.418],
                      [48.535, -41.162],
                      [51.27, -51.904],
                      [45.19, -66.797],
                      [28.125, -72.07],
                      [15.283, -69.019],
                      [6.519, -60.596],
                      [3.418, -48.73],
                      [17.578, -48.73],
                      [20.337, -57.397],
                      [27.881, -60.693],
                      [34.717, -57.983],
                      [37.109, -50.537],
                      [34.839, -43.213],
                      [27.832, -34.18],
                      [4.834, -9.668],
                      [4.834, 0],
                      [53.564, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "2",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "2",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "3",
      size: 36,
      style: "Bold",
      w: 57.37,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, -7.096],
                      [1.904, -1.774],
                      [3.352, 0],
                      [2.002, 1.693],
                      [0, 2.67],
                      [0, 0],
                      [-4.509, -3.743],
                      [-7.097, 0],
                      [-4.607, 3.809],
                      [0, 6.316],
                      [1.969, 2.865],
                      [4.004, 1.433],
                      [-1.986, 2.734],
                      [0, 3.255],
                      [4.264, 3.597],
                      [7.52, 0],
                      [3.596, -1.66],
                      [2.002, -2.946],
                      [0, -3.711],
                      [0, 0],
                      [-1.823, 1.482],
                      [-2.702, 0],
                      [-1.709, -1.595],
                      [0, -2.864],
                      [1.725, -1.79],
                      [3.58, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [7.812, 0],
                      [0, 2.93],
                      [-1.904, 1.775],
                      [-2.93, 0],
                      [-2.002, -1.692],
                      [0, 0],
                      [0, 6.152],
                      [4.508, 3.744],
                      [7.584, 0],
                      [4.606, -3.809],
                      [0, -3.906],
                      [-1.97, -2.864],
                      [3.223, -1.465],
                      [1.985, -2.734],
                      [0, -6.315],
                      [-4.265, -3.596],
                      [-4.33, 0],
                      [-3.597, 1.66],
                      [-2.002, 2.946],
                      [0, 0],
                      [0, -2.376],
                      [1.823, -1.481],
                      [2.995, 0],
                      [1.709, 1.595],
                      [0, 2.962],
                      [-1.726, 1.791],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [19.189, -30.762],
                      [26.66, -30.762],
                      [38.379, -20.117],
                      [35.522, -13.062],
                      [27.637, -10.4],
                      [20.239, -12.939],
                      [17.236, -19.482],
                      [3.125, -19.482],
                      [9.888, -4.639],
                      [27.295, 0.977],
                      [45.581, -4.736],
                      [52.49, -19.922],
                      [49.536, -30.078],
                      [40.576, -36.523],
                      [48.389, -42.822],
                      [51.367, -51.807],
                      [44.971, -66.675],
                      [27.295, -72.07],
                      [15.405, -69.58],
                      [7.007, -62.671],
                      [4.004, -52.686],
                      [18.115, -52.686],
                      [20.85, -58.472],
                      [27.637, -60.693],
                      [34.692, -58.301],
                      [37.256, -51.611],
                      [34.668, -44.482],
                      [26.709, -41.797],
                      [19.189, -41.797],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "3",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "3",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "4",
      size: 36,
      style: "Bold",
      w: 57.37,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [46.582, -71.094],
                      [32.324, -71.094],
                      [2.686, -24.268],
                      [3.32, -15.381],
                      [32.471, -15.381],
                      [32.471, 0],
                      [46.582, 0],
                      [46.582, -15.381],
                      [54.639, -15.381],
                      [54.639, -26.758],
                      [46.582, -26.758],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "4",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [31.543, -50.244],
                      [32.471, -51.855],
                      [32.471, -26.758],
                      [16.748, -26.758],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "4",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "4",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "5",
      size: 36,
      style: "Bold",
      w: 57.37,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [-3.353, 0],
                      [-1.986, -2.148],
                      [0, -4.036],
                      [1.725, -2.295],
                      [3.19, 0],
                      [1.774, 1.644],
                      [0.293, 2.962],
                      [0, 0],
                      [-2.132, -3.157],
                      [-3.679, -1.741],
                      [-4.297, 0],
                      [-3.613, 2.002],
                      [-1.937, 3.63],
                      [0, 4.59],
                      [3.841, 4.199],
                      [6.77, 0],
                      [3.352, -1.79],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [2.083, -1.758],
                      [3.645, 0],
                      [1.985, 2.148],
                      [0, 4.199],
                      [-1.726, 2.295],
                      [-2.865, 0],
                      [-1.775, -1.643],
                      [0, 0],
                      [0.13, 4.037],
                      [2.132, 3.158],
                      [3.678, 1.741],
                      [4.915, 0],
                      [3.613, -2.002],
                      [1.936, -3.629],
                      [0, -7.552],
                      [-3.841, -4.199],
                      [-3.777, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [18.164, -32.178],
                      [19.531, -33.447],
                      [27.686, -36.084],
                      [36.133, -32.861],
                      [39.111, -23.584],
                      [36.523, -13.843],
                      [29.15, -10.4],
                      [22.192, -12.866],
                      [19.092, -19.775],
                      [5.127, -19.775],
                      [8.521, -8.984],
                      [17.236, -1.636],
                      [29.199, 0.977],
                      [41.992, -2.026],
                      [50.317, -10.474],
                      [53.223, -22.803],
                      [47.461, -40.43],
                      [31.543, -46.729],
                      [20.85, -44.043],
                      [22.607, -59.326],
                      [50.879, -59.326],
                      [50.879, -71.094],
                      [11.035, -71.094],
                      [6.934, -34.961],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "5",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "5",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "6",
      size: 36,
      style: "Bold",
      w: 57.37,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [5.452, -3.239],
                      [2.913, -5.908],
                      [0, -7.715],
                      [0, 0],
                      [-4.607, -5.208],
                      [-7.487, 0],
                      [-3.662, 2.084],
                      [-2.002, 3.744],
                      [0, 4.623],
                      [3.645, 4.427],
                      [6.184, 0],
                      [3.776, -3.841],
                      [-3.89, 3.223],
                      [-6.38, 0.098],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [-7.195, 0.033],
                      [-5.453, 3.239],
                      [-2.914, 5.908],
                      [0, 0],
                      [0, 8.691],
                      [4.606, 5.209],
                      [4.622, 0],
                      [3.662, -2.083],
                      [2.002, -3.743],
                      [0, -7.227],
                      [-3.646, -4.427],
                      [-5.762, 0],
                      [0.781, -5.729],
                      [3.889, -3.223],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [40.771, -71.826],
                      [21.802, -66.919],
                      [9.253, -53.198],
                      [4.883, -32.764],
                      [4.883, -27.686],
                      [11.792, -6.836],
                      [29.932, 0.977],
                      [42.358, -2.148],
                      [50.854, -10.889],
                      [53.857, -23.438],
                      [48.389, -40.918],
                      [33.643, -47.559],
                      [19.336, -41.797],
                      [26.343, -55.225],
                      [41.748, -60.205],
                      [43.115, -60.205],
                      [43.115, -71.826],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "6",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-1.888, -2.371],
                      [0, -3.867],
                      [1.871, -2.388],
                      [3.027, 0],
                      [1.855, 2.648],
                      [0, 4.712],
                      [0, 0],
                      [-1.855, 1.186],
                      [-2.279, 0],
                    ],
                    o: [
                      [1.888, 2.372],
                      [0, 3.802],
                      [-1.872, 2.388],
                      [-3.353, 0],
                      [-1.855, -2.647],
                      [0, 0],
                      [0.879, -1.949],
                      [1.855, -1.186],
                      [3.19, 0],
                    ],
                    v: [
                      [36.963, -32.624],
                      [39.795, -23.267],
                      [36.987, -13.982],
                      [29.639, -10.4],
                      [21.826, -14.372],
                      [19.043, -25.411],
                      [19.043, -29.7],
                      [23.145, -34.402],
                      [29.346, -36.182],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "6",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "6",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "7",
      size: 36,
      style: "Bold",
      w: 57.37,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [53.174, -71.094],
                      [2.979, -71.094],
                      [2.979, -59.717],
                      [38.33, -59.717],
                      [10.791, 0],
                      [25.684, 0],
                      [53.174, -63.217],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "7",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "7",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "8",
      size: 36,
      style: "Bold",
      w: 57.37,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [4.053, 3.564],
                      [6.901, 0],
                      [4.036, -3.596],
                      [0, -6.055],
                      [-1.709, -2.669],
                      [-3.027, -1.595],
                      [2.018, -2.93],
                      [0, -3.938],
                      [-4.33, -3.678],
                      [-7.39, 0],
                      [-4.297, 3.662],
                      [0, 6.316],
                      [2.018, 2.914],
                      [3.45, 1.66],
                      [-1.726, 2.67],
                      [0, 3.451],
                    ],
                    o: [
                      [-4.053, -3.564],
                      [-6.934, 0],
                      [-4.037, 3.597],
                      [0, 3.451],
                      [1.709, 2.67],
                      [-3.418, 1.628],
                      [-2.019, 2.93],
                      [0, 6.283],
                      [4.329, 3.679],
                      [7.389, 0],
                      [4.297, -3.662],
                      [0, -3.938],
                      [-2.019, -2.913],
                      [3.027, -1.595],
                      [1.725, -2.669],
                      [0, -6.12],
                    ],
                    v: [
                      [45.093, -66.724],
                      [28.662, -72.07],
                      [12.207, -66.675],
                      [6.152, -52.197],
                      [8.716, -43.018],
                      [15.82, -36.621],
                      [7.666, -29.785],
                      [4.639, -19.482],
                      [11.133, -4.541],
                      [28.711, 0.977],
                      [46.24, -4.517],
                      [52.686, -19.482],
                      [49.658, -29.761],
                      [41.455, -36.621],
                      [48.584, -43.018],
                      [51.172, -52.197],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "8",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.741, -1.79],
                      [3.059, 0],
                      [1.758, 1.855],
                      [0, 3.027],
                      [-1.791, 1.839],
                      [-2.995, 0],
                      [-1.791, -1.855],
                      [0, -3.092],
                    ],
                    o: [
                      [-1.742, 1.791],
                      [-3.125, 0],
                      [-1.758, -1.855],
                      [0, -3.125],
                      [1.79, -1.839],
                      [3.027, 0],
                      [1.79, 1.855],
                      [0, 3.158],
                    ],
                    v: [
                      [35.913, -13.086],
                      [28.711, -10.4],
                      [21.387, -13.184],
                      [18.75, -20.508],
                      [21.436, -27.954],
                      [28.613, -30.713],
                      [35.84, -27.93],
                      [38.525, -20.508],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "8",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 2,
                ty: "sh",
                ix: 3,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.448, -1.725],
                      [2.669, 0],
                      [1.465, 1.726],
                      [0, 2.832],
                      [-1.465, 1.628],
                      [-2.637, 0],
                      [-1.465, -1.676],
                      [0, -2.766],
                    ],
                    o: [
                      [-1.449, 1.726],
                      [-2.67, 0],
                      [-1.465, -1.725],
                      [0, -2.864],
                      [1.465, -1.627],
                      [2.669, 0],
                      [1.465, 1.677],
                      [0, 2.832],
                    ],
                    v: [
                      [34.888, -44.678],
                      [28.711, -42.09],
                      [22.51, -44.678],
                      [20.312, -51.514],
                      [22.51, -58.252],
                      [28.662, -60.693],
                      [34.863, -58.179],
                      [37.061, -51.514],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "8",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "8",
            np: 6,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "9",
      size: 36,
      style: "Bold",
      w: 57.37,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [12.272, -0.553],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.892, 6.771],
                      [0, 11.849],
                      [0, 0],
                      [2.018, 4.395],
                      [3.645, 2.344],
                      [4.688, 0],
                      [3.645, -2.164],
                      [2.034, -3.889],
                      [0, -4.655],
                      [-3.777, -4.313],
                      [-6.283, 0],
                      [-3.679, 3.613],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [10.807, -0.456],
                      [5.891, -6.77],
                      [0, 0],
                      [-0.033, -5.664],
                      [-2.019, -4.395],
                      [-3.646, -2.344],
                      [-4.558, 0],
                      [-3.646, 2.165],
                      [-2.035, 3.89],
                      [0, 7.324],
                      [3.776, 4.314],
                      [4.915, 0],
                      [-1.009, 10.97],
                    ],
                    v: [
                      [17.92, -11.133],
                      [14.795, -11.084],
                      [14.795, 0.684],
                      [18.262, 0.635],
                      [43.311, -10.205],
                      [52.148, -38.135],
                      [52.148, -43.359],
                      [49.072, -58.447],
                      [40.576, -68.555],
                      [28.076, -72.07],
                      [15.771, -68.823],
                      [7.251, -59.741],
                      [4.199, -46.924],
                      [9.863, -29.468],
                      [24.951, -22.998],
                      [37.842, -28.418],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "9",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.774, 2.49],
                      [0, 3.874],
                      [-1.823, 2.588],
                      [-2.832, 0],
                      [-1.823, -2.637],
                      [0, -4.785],
                      [0, 0],
                      [1.643, -1.172],
                      [2.278, 0],
                    ],
                    o: [
                      [-1.775, -2.49],
                      [0, -3.906],
                      [1.823, -2.588],
                      [3.059, 0],
                      [1.823, 2.637],
                      [0, 0],
                      [-0.847, 1.66],
                      [-1.644, 1.172],
                      [-3.223, 0],
                    ],
                    v: [
                      [20.923, -37.524],
                      [18.262, -47.07],
                      [20.996, -56.812],
                      [27.979, -60.693],
                      [35.303, -56.738],
                      [38.037, -45.605],
                      [38.037, -39.795],
                      [34.302, -35.547],
                      [28.418, -33.789],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "9",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "9",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "-",
      size: 36,
      style: "Bold",
      w: 38.77,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [32.861, -36.133],
                      [5.371, -36.133],
                      [5.371, -24.756],
                      [32.861, -24.756],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "-",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "-",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "o",
      size: 26,
      style: "Bold",
      w: 56.54,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-4.541, -4.883],
                      [-7.617, 0],
                      [-4.525, 4.9],
                      [0, 8.105],
                      [0, 0],
                      [4.508, 4.362],
                      [7.129, 0],
                      [3.792, -2.246],
                      [2.018, -4.102],
                      [0, -5.241],
                    ],
                    o: [
                      [0, 8.398],
                      [4.541, 4.883],
                      [7.617, 0],
                      [4.524, -4.899],
                      [0, 0],
                      [-0.521, -7.487],
                      [-4.509, -4.362],
                      [-5.013, 0],
                      [-3.793, 2.246],
                      [-2.019, 4.102],
                      [0, 0],
                    ],
                    v: [
                      [3.223, -26.27],
                      [10.034, -6.348],
                      [28.271, 0.977],
                      [46.484, -6.372],
                      [53.271, -25.879],
                      [53.174, -29.492],
                      [45.63, -47.266],
                      [28.174, -53.809],
                      [14.966, -50.439],
                      [6.25, -40.918],
                      [3.223, -26.904],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "o",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-1.888, 2.69],
                      [-3.451, 0],
                      [-1.921, -2.705],
                      [0, -4.922],
                      [1.92, -2.625],
                      [3.418, 0],
                      [1.888, 2.657],
                      [0, 5.021],
                    ],
                    o: [
                      [1.888, -2.689],
                      [3.483, 0],
                      [1.92, 2.706],
                      [0, 5.771],
                      [-1.921, 2.625],
                      [-3.516, 0],
                      [-1.888, -2.657],
                      [0, -5.64],
                    ],
                    v: [
                      [20.166, -38.397],
                      [28.174, -42.432],
                      [36.279, -38.373],
                      [39.16, -26.929],
                      [36.279, -14.337],
                      [28.271, -10.4],
                      [20.166, -14.386],
                      [17.334, -25.903],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "o",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "o",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "t",
      size: 26,
      style: "Bold",
      w: 33.79,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-9.896, 0],
                      [-2.767, 0.846],
                      [0, 0],
                      [1.562, 0],
                      [0.748, 0.847],
                      [0, 1.953],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.195, 9.994],
                      [2.93, 0],
                      [0, 0],
                      [-1.205, 0.228],
                      [-2.116, 0],
                      [-0.749, -0.846],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.203, -65.82],
                      [8.203, -52.832],
                      [0.488, -52.832],
                      [0.488, -42.48],
                      [8.203, -42.48],
                      [8.203, -14.014],
                      [23.34, 0.977],
                      [31.885, -0.293],
                      [31.885, -10.986],
                      [27.734, -10.645],
                      [23.438, -11.914],
                      [22.314, -16.113],
                      [22.314, -42.48],
                      [31.348, -42.48],
                      [31.348, -52.832],
                      [22.314, -52.832],
                      [22.314, -65.82],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "t",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "t",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "a",
      size: 26,
      style: "Bold",
      w: 53.61,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0.032, 5.013],
                      [0, 0],
                      [3.825, 3.271],
                      [6.51, 0],
                      [3.45, -1.481],
                      [1.888, -2.604],
                      [0, -3.059],
                      [0, 0],
                      [-1.351, 1.14],
                      [-2.377, 0],
                      [-1.254, -1.465],
                      [0, -2.441],
                      [0, 0],
                      [0, 0],
                      [4.15, -2.995],
                      [0, -5.598],
                      [-3.402, -2.995],
                      [-5.176, 0],
                      [-3.418, 3.809],
                      [-0.652, -1.27],
                    ],
                    o: [
                      [0, 0],
                      [-1.367, -2.571],
                      [0, 0],
                      [0, -5.924],
                      [-3.825, -3.271],
                      [-4.297, 0],
                      [-3.451, 1.482],
                      [-1.888, 2.605],
                      [0, 0],
                      [0, -1.985],
                      [1.35, -1.139],
                      [2.702, 0],
                      [1.253, 1.465],
                      [0, 0],
                      [0, 0],
                      [-7.845, 0.033],
                      [-4.15, 2.995],
                      [0, 4.558],
                      [3.401, 2.995],
                      [5.469, 0],
                      [0.293, 1.888],
                      [0, 0],
                    ],
                    v: [
                      [50.293, 0],
                      [50.293, -0.83],
                      [48.193, -12.207],
                      [48.193, -35.107],
                      [42.456, -48.901],
                      [26.953, -53.809],
                      [15.332, -51.587],
                      [7.324, -45.459],
                      [4.492, -36.963],
                      [18.604, -36.963],
                      [20.63, -41.65],
                      [26.221, -43.359],
                      [32.153, -41.162],
                      [34.033, -35.303],
                      [34.033, -32.275],
                      [27.539, -32.275],
                      [9.546, -27.734],
                      [3.32, -14.844],
                      [8.423, -3.516],
                      [21.289, 0.977],
                      [34.619, -4.736],
                      [36.035, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "a",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.237, 1.14],
                      [0, 1.758],
                      [0, 0],
                      [-7.064, 0],
                      [0, 0],
                      [0, 0],
                      [1.758, -0.928],
                      [2.083, 0],
                    ],
                    o: [
                      [-1.237, -1.139],
                      [0, 0],
                      [0.455, -4.883],
                      [0, 0],
                      [0, 0],
                      [-0.847, 1.562],
                      [-1.758, 0.928],
                      [-2.148, 0],
                    ],
                    v: [
                      [19.287, -11.523],
                      [17.432, -15.869],
                      [17.48, -16.699],
                      [28.76, -24.023],
                      [34.033, -24.023],
                      [34.033, -14.941],
                      [30.127, -11.206],
                      [24.365, -9.814],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "a",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "a",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "l",
      size: 26,
      style: "Bold",
      w: 26.51,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [20.312, -75],
                      [6.152, -75],
                      [6.152, 0],
                      [20.312, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "l",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "l",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "V",
      size: 26,
      style: "Bold",
      w: 65.38,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [16.602, -71.094],
                      [0.342, -71.094],
                      [25, 0],
                      [40.283, 0],
                      [65.039, -71.094],
                      [48.73, -71.094],
                      [32.617, -17.627],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "V",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "V",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "i",
      size: 26,
      style: "Bold",
      w: 26.51,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [20.312, -52.832],
                      [6.152, -52.832],
                      [6.152, 0],
                      [20.312, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "i",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-1.449, -1.367],
                      [-2.377, 0],
                      [-1.449, 1.367],
                      [0, 2.148],
                      [1.432, 1.367],
                      [2.409, 0],
                      [1.416, -1.367],
                      [0, -2.116],
                    ],
                    o: [
                      [1.448, 1.367],
                      [2.376, 0],
                      [1.448, -1.367],
                      [0, -2.116],
                      [-1.433, -1.367],
                      [-2.441, 0],
                      [-1.416, 1.367],
                      [0, 2.148],
                    ],
                    v: [
                      [7.495, -61.23],
                      [13.232, -59.18],
                      [18.97, -61.23],
                      [21.143, -66.504],
                      [18.994, -71.729],
                      [13.232, -73.779],
                      [7.446, -71.729],
                      [5.322, -66.504],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "i",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "i",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "s",
      size: 26,
      style: "Bold",
      w: 51.42,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.383, -1.025],
                      [2.702, 0],
                      [1.725, 1.335],
                      [0.13, 2.507],
                      [0, 0],
                      [-1.953, -2.75],
                      [-3.435, -1.579],
                      [-4.427, 0],
                      [-4.134, 2.979],
                      [0, 4.818],
                      [7.454, 2.734],
                      [3.516, 0.7],
                      [1.481, 0.961],
                      [0, 1.562],
                      [-1.302, 1.074],
                      [-2.377, 0],
                      [-1.302, -1.318],
                      [0, -2.018],
                      [0, 0],
                      [4.02, 3.158],
                      [6.705, 0],
                      [3.889, -3.141],
                      [0, -4.688],
                      [-12.566, -2.637],
                      [-1.709, -0.993],
                      [0, -1.725],
                    ],
                    o: [
                      [-1.384, 1.025],
                      [-2.898, 0],
                      [-1.726, -1.334],
                      [0, 0],
                      [0, 3.191],
                      [1.953, 2.751],
                      [3.434, 1.579],
                      [6.543, 0],
                      [4.134, -2.979],
                      [0, -6.738],
                      [-2.409, -0.879],
                      [-3.516, -0.7],
                      [-1.482, -0.96],
                      [0, -1.66],
                      [1.302, -1.074],
                      [2.766, 0],
                      [1.302, 1.318],
                      [0, 0],
                      [0, -5.045],
                      [-4.021, -3.157],
                      [-6.283, 0],
                      [-3.89, 3.142],
                      [0, 8.041],
                      [3.776, 0.781],
                      [1.709, 0.993],
                      [0, 1.66],
                    ],
                    v: [
                      [31.958, -10.571],
                      [25.83, -9.033],
                      [18.896, -11.035],
                      [16.113, -16.797],
                      [2.734, -16.797],
                      [5.664, -7.886],
                      [13.745, -1.392],
                      [25.537, 0.977],
                      [41.553, -3.491],
                      [47.754, -15.186],
                      [36.572, -29.395],
                      [27.686, -31.763],
                      [20.19, -34.253],
                      [17.969, -38.037],
                      [19.922, -42.139],
                      [25.439, -43.75],
                      [31.543, -41.772],
                      [33.496, -36.768],
                      [47.607, -36.768],
                      [41.577, -49.072],
                      [25.488, -53.809],
                      [10.229, -49.097],
                      [4.395, -37.354],
                      [23.242, -21.338],
                      [31.47, -18.677],
                      [34.033, -14.6],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "s",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "s",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: ":",
      size: 26,
      style: "Bold",
      w: 28.22,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-1.498, -1.383],
                      [-2.312, 0],
                      [-1.498, 1.384],
                      [0, 2.214],
                      [1.514, 1.4],
                      [2.311, 0],
                      [1.514, -1.399],
                      [0, -2.246],
                    ],
                    o: [
                      [1.497, 1.384],
                      [2.344, 0],
                      [1.497, -1.383],
                      [0, -2.246],
                      [-1.514, -1.399],
                      [-2.279, 0],
                      [-1.514, 1.4],
                      [0, 2.214],
                    ],
                    v: [
                      [8.35, -1.538],
                      [14.062, 0.537],
                      [19.824, -1.538],
                      [22.07, -6.934],
                      [19.8, -12.402],
                      [14.062, -14.502],
                      [8.374, -12.402],
                      [6.104, -6.934],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: ":",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-1.498, -1.383],
                      [-2.312, 0],
                      [-1.498, 1.384],
                      [0, 2.214],
                      [1.514, 1.4],
                      [2.311, 0],
                      [1.514, -1.399],
                      [0, -2.246],
                    ],
                    o: [
                      [1.497, 1.384],
                      [2.344, 0],
                      [1.497, -1.383],
                      [0, -2.246],
                      [-1.514, -1.399],
                      [-2.279, 0],
                      [-1.514, 1.4],
                      [0, 2.214],
                    ],
                    v: [
                      [8.35, -41.528],
                      [14.062, -39.453],
                      [19.824, -41.528],
                      [22.07, -46.924],
                      [19.8, -52.393],
                      [14.062, -54.492],
                      [8.374, -52.393],
                      [6.104, -46.924],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: ":",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: ":",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "M",
      size: 26,
      style: "Bold",
      w: 87.6,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [6.348, -71.094],
                      [6.348, 0],
                      [20.996, 0],
                      [20.996, -19.434],
                      [19.531, -52.93],
                      [38.672, 0],
                      [48.73, 0],
                      [67.92, -52.979],
                      [66.455, -19.434],
                      [66.455, 0],
                      [81.152, 0],
                      [81.152, -71.094],
                      [61.914, -71.094],
                      [43.75, -19.531],
                      [25.488, -71.094],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "M",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "M",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "e",
      size: 26,
      style: "Bold",
      w: 54.05,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-3.613, 1.676],
                      [-2.116, 2.995],
                      [0, 0],
                      [5.436, 0],
                      [2.295, 2.084],
                      [0.455, 3.451],
                      [0, 0],
                      [0, 0],
                      [4.15, 4.558],
                      [7.227, 0],
                      [3.76, -2.262],
                      [2.051, -4.182],
                      [0, -5.305],
                      [0, 0],
                      [-4.867, -4.752],
                      [-7.748, 0],
                    ],
                    o: [
                      [3.613, -1.676],
                      [0, 0],
                      [-3.06, 3.939],
                      [-3.516, 0],
                      [-2.295, -2.083],
                      [0, 0],
                      [0, 0],
                      [0, -8.366],
                      [-4.15, -4.557],
                      [-4.818, 0],
                      [-3.76, 2.263],
                      [-2.051, 4.183],
                      [0, 0],
                      [0, 7.91],
                      [4.866, 4.753],
                      [4.395, 0],
                    ],
                    v: [
                      [41.748, -1.538],
                      [50.342, -8.545],
                      [43.408, -16.309],
                      [30.664, -10.4],
                      [21.948, -13.525],
                      [17.822, -21.826],
                      [51.465, -21.826],
                      [51.465, -27.588],
                      [45.239, -46.973],
                      [28.174, -53.809],
                      [15.308, -50.415],
                      [6.592, -40.747],
                      [3.516, -26.514],
                      [3.516, -25.146],
                      [10.815, -6.152],
                      [29.736, 0.977],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "e",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-1.628, -1.717],
                      [-0.066, -3.139],
                      [0, 0],
                      [0, 0],
                      [-1.742, 1.897],
                      [-2.8, 0],
                    ],
                    o: [
                      [1.627, 1.717],
                      [0, 0],
                      [0, 0],
                      [0.488, -3.531],
                      [1.741, -1.897],
                      [2.995, 0],
                    ],
                    v: [
                      [35.059, -39.857],
                      [37.598, -32.574],
                      [37.598, -31.445],
                      [17.969, -31.445],
                      [21.313, -39.587],
                      [28.125, -42.432],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "e",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "e",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "d",
      size: 26,
      style: "Bold",
      w: 56.35,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-3.76, -4.899],
                      [-6.218, 0],
                      [-3.516, 4.395],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [5.143, 0],
                      [3.694, -4.883],
                      [0, -8.235],
                    ],
                    o: [
                      [3.76, 4.9],
                      [5.534, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-3.353, -3.841],
                      [-6.413, 0],
                      [-3.695, 4.883],
                      [0, 8.724],
                    ],
                    v: [
                      [8.862, -6.372],
                      [23.828, 0.977],
                      [37.402, -5.615],
                      [38.086, 0],
                      [50.83, 0],
                      [50.83, -75],
                      [36.67, -75],
                      [36.67, -48.047],
                      [23.926, -53.809],
                      [8.765, -46.484],
                      [3.223, -26.807],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "d",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-6.804, 0],
                      [-1.758, -3.695],
                      [0, 0],
                      [4.362, 0],
                      [1.725, 2.649],
                      [0, 4.971],
                    ],
                    o: [
                      [4.329, 0],
                      [0, 0],
                      [-1.791, 3.696],
                      [-3.288, 0],
                      [-1.726, -2.649],
                      [0, -11.086],
                    ],
                    v: [
                      [27.539, -42.432],
                      [36.67, -36.889],
                      [36.67, -15.943],
                      [27.441, -10.4],
                      [19.922, -14.374],
                      [17.334, -25.803],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "d",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "d",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "/",
      size: 26,
      style: "Bold",
      w: 25.24,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [36.035, -71.094],
                      [25.537, -71.094],
                      [-0.635, 6.104],
                      [9.863, 6.104],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "/",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "/",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "2",
      size: 36,
      style: "Regular",
      w: 56.15,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 6.999],
                      [3.841, 3.467],
                      [6.608, 0],
                      [4.215, -4.036],
                      [0, -6.543],
                      [0, 0],
                      [-2.49, 2.556],
                      [-4.492, 0],
                      [-2.246, -2.376],
                      [0, -3.711],
                      [1.383, -2.588],
                      [3.645, -4.134],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [8.919, -9.668],
                      [0, -5.762],
                      [-3.841, -3.467],
                      [-7.064, 0],
                      [-4.216, 4.037],
                      [0, 0],
                      [0, -4.557],
                      [2.49, -2.555],
                      [3.743, 0],
                      [2.246, 2.377],
                      [0, 2.767],
                      [-1.384, 2.588],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [52.49, -7.373],
                      [16.797, -7.373],
                      [35.84, -28.027],
                      [49.219, -53.027],
                      [43.457, -66.87],
                      [27.783, -72.07],
                      [10.864, -66.016],
                      [4.541, -50.146],
                      [13.574, -50.146],
                      [17.31, -60.815],
                      [27.783, -64.648],
                      [36.768, -61.084],
                      [40.137, -51.953],
                      [38.062, -43.921],
                      [30.518, -33.838],
                      [5.908, -6.494],
                      [5.908, 0],
                      [52.49, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "2",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "2",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "0",
      size: 36,
      style: "Regular",
      w: 56.15,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [3.613, 4.9],
                      [7.649, 0],
                      [3.613, -5.078],
                      [0, -10.449],
                      [0, 0],
                      [-3.646, -5.029],
                      [-7.585, 0],
                      [-3.613, 5.144],
                      [0, 10.58],
                    ],
                    o: [
                      [-0.098, -10.221],
                      [-3.613, -4.899],
                      [-7.715, 0],
                      [-3.613, 5.078],
                      [0, 0],
                      [0.13, 9.994],
                      [3.645, 5.029],
                      [7.682, 0],
                      [3.613, -5.143],
                      [0, 0],
                    ],
                    v: [
                      [50.488, -42.041],
                      [44.922, -64.722],
                      [28.027, -72.07],
                      [11.035, -64.453],
                      [5.615, -41.162],
                      [5.615, -29.102],
                      [11.279, -6.567],
                      [28.125, 0.977],
                      [45.068, -6.738],
                      [50.488, -30.322],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "0",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [2.164, -3.45],
                      [4.459, 0],
                      [2.229, 3.662],
                      [0, 7.683],
                      [0, 0],
                      [-2.116, 3.484],
                      [-4.623, 0],
                      [-2.148, -3.499],
                      [0, -7.649],
                    ],
                    o: [
                      [-0.098, 7.422],
                      [-2.165, 3.451],
                      [-4.525, 0],
                      [-2.23, -3.662],
                      [0, 0],
                      [0.065, -7.227],
                      [2.116, -3.483],
                      [4.655, 0],
                      [2.148, 3.5],
                      [0, 0],
                    ],
                    v: [
                      [41.455, -27.881],
                      [38.062, -11.572],
                      [28.125, -6.396],
                      [17.993, -11.89],
                      [14.648, -28.906],
                      [14.648, -43.359],
                      [17.92, -59.424],
                      [28.027, -64.648],
                      [38.232, -59.399],
                      [41.455, -42.676],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "0",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "0",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "1",
      size: 36,
      style: "Regular",
      w: 56.15,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [35.596, -71.436],
                      [34.18, -71.436],
                      [8.301, -61.719],
                      [8.301, -53.516],
                      [26.514, -60.205],
                      [26.514, 0],
                      [35.596, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "1",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "1",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "-",
      size: 36,
      style: "Regular",
      w: 27.59,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [25.635, -33.887],
                      [1.807, -33.887],
                      [1.807, -26.514],
                      [25.635, -26.514],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "-",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "-",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "6",
      size: 36,
      style: "Regular",
      w: 56.15,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [5.582, -6.917],
                      [0, -13.086],
                      [0, 0],
                      [-4.232, -5.225],
                      [-6.804, 0],
                      [-3.988, 4.395],
                      [0, 7.324],
                      [3.694, 4.362],
                      [6.184, 0],
                      [3.743, -4.297],
                      [-4.167, 4.037],
                      [-7.031, 0.13],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [-11.035, 0.163],
                      [-5.583, 6.918],
                      [0, 0],
                      [0, 8.236],
                      [4.231, 5.225],
                      [6.705, 0],
                      [3.987, -4.395],
                      [0, -6.901],
                      [-3.695, -4.362],
                      [-6.478, 0],
                      [0.651, -7.324],
                      [4.166, -4.036],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [39.746, -71.143],
                      [14.819, -60.522],
                      [6.445, -30.518],
                      [6.445, -27.051],
                      [12.793, -6.86],
                      [29.346, 0.977],
                      [45.386, -5.615],
                      [51.367, -23.193],
                      [45.825, -40.088],
                      [31.006, -46.631],
                      [15.674, -40.186],
                      [22.9, -57.227],
                      [39.697, -63.477],
                      [41.357, -63.477],
                      [41.357, -71.143],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "6",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-2.361, -3.011],
                      [0, -4.883],
                      [2.327, -2.995],
                      [4.069, 0],
                      [2.637, 3.581],
                      [0, 5.859],
                      [0, 0],
                      [-2.605, 1.855],
                      [-3.093, 0],
                    ],
                    o: [
                      [2.36, 3.011],
                      [0, 4.851],
                      [-2.328, 2.995],
                      [-3.939, 0],
                      [-2.637, -3.58],
                      [0, 0],
                      [1.009, -2.799],
                      [2.604, -1.855],
                      [3.906, 0],
                    ],
                    v: [
                      [38.892, -34.595],
                      [42.432, -22.754],
                      [38.94, -10.986],
                      [29.346, -6.494],
                      [19.482, -11.865],
                      [15.527, -26.025],
                      [15.527, -29.346],
                      [20.947, -36.328],
                      [29.492, -39.111],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "6",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "6",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "7",
      size: 36,
      style: "Regular",
      w: 56.15,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [51.807, -71.094],
                      [3.76, -71.094],
                      [3.76, -63.672],
                      [42.236, -63.672],
                      [12.891, 0],
                      [22.363, 0],
                      [51.807, -66.016],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "7",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "7",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "8",
      size: 36,
      style: "Regular",
      w: 56.15,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [3.857, 3.516],
                      [6.25, 0],
                      [3.841, -3.516],
                      [0, -5.989],
                      [-1.823, -2.766],
                      [-3.158, -1.562],
                      [2.1, -3.092],
                      [0, -3.841],
                      [-4.118, -3.662],
                      [-6.836, 0],
                      [-4.15, 3.646],
                      [0, 6.152],
                      [2.164, 3.06],
                      [3.711, 1.595],
                      [-1.872, 2.767],
                      [0, 3.548],
                    ],
                    o: [
                      [-3.857, -3.516],
                      [-6.283, 0],
                      [-3.841, 3.516],
                      [0, 3.516],
                      [1.823, 2.767],
                      [-3.711, 1.595],
                      [-2.1, 3.093],
                      [0, 6.12],
                      [4.118, 3.662],
                      [6.77, 0],
                      [4.15, -3.645],
                      [0, -3.873],
                      [-2.165, -3.059],
                      [3.19, -1.562],
                      [1.871, -2.766],
                      [0, -5.989],
                    ],
                    v: [
                      [43.237, -66.797],
                      [28.076, -72.07],
                      [12.891, -66.797],
                      [7.129, -52.539],
                      [9.863, -43.115],
                      [17.334, -36.621],
                      [8.618, -29.59],
                      [5.469, -19.189],
                      [11.646, -4.517],
                      [28.076, 0.977],
                      [44.458, -4.492],
                      [50.684, -19.189],
                      [47.437, -29.59],
                      [38.623, -36.572],
                      [46.216, -43.066],
                      [49.023, -52.539],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "8",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [2.409, -2.327],
                      [4.231, 0],
                      [2.393, 2.312],
                      [0, 4.037],
                      [-2.458, 2.474],
                      [-4.07, 0],
                      [-2.523, -2.506],
                      [0, -3.971],
                    ],
                    o: [
                      [-2.409, 2.328],
                      [-4.265, 0],
                      [-2.393, -2.311],
                      [0, -4.036],
                      [2.457, -2.473],
                      [4.069, 0],
                      [2.522, 2.507],
                      [0, 4.004],
                    ],
                    v: [
                      [38.037, -9.888],
                      [28.076, -6.396],
                      [18.091, -9.863],
                      [14.502, -19.385],
                      [18.188, -29.15],
                      [27.979, -32.861],
                      [37.866, -29.102],
                      [41.65, -19.385],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "8",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 2,
                ty: "sh",
                ix: 3,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-2.246, -2.278],
                      [0, -3.613],
                      [2.164, -2.229],
                      [3.613, 0],
                      [2.164, 2.23],
                      [0, 3.613],
                      [-2.197, 2.197],
                      [-3.548, 0],
                    ],
                    o: [
                      [2.246, 2.279],
                      [0, 3.613],
                      [-2.165, 2.23],
                      [-3.613, 0],
                      [-2.165, -2.229],
                      [0, -3.776],
                      [2.197, -2.197],
                      [3.45, 0],
                    ],
                    v: [
                      [36.621, -61.23],
                      [39.99, -52.393],
                      [36.743, -43.628],
                      [28.076, -40.283],
                      [19.409, -43.628],
                      [16.162, -52.393],
                      [19.458, -61.353],
                      [28.076, -64.648],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "8",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "8",
            np: 6,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "9",
      size: 36,
      style: "Regular",
      w: 56.15,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [4.004, -3.752],
                      [7.454, -0.13],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.469, 6.364],
                      [0, 13.835],
                      [0, 0],
                      [4.053, 5.257],
                      [7.031, 0],
                      [3.32, -2.018],
                      [1.807, -3.809],
                      [0, -4.752],
                      [-1.66, -3.662],
                      [-3.044, -2.018],
                      [-4.102, 0],
                      [-2.621, 1.367],
                      [-1.888, 2.246],
                    ],
                    o: [
                      [-4.004, 3.752],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [11.035, -0.163],
                      [5.469, -6.364],
                      [0, 0],
                      [0, -9.082],
                      [-4.053, -5.257],
                      [-4.427, 0],
                      [-3.32, 2.019],
                      [-1.807, 3.809],
                      [0, 4.427],
                      [1.66, 3.662],
                      [3.043, 2.019],
                      [3.125, 0],
                      [2.62, -1.367],
                      [-0.359, 8.121],
                    ],
                    v: [
                      [33.984, -13.44],
                      [16.797, -7.617],
                      [14.893, -7.617],
                      [14.893, 0.049],
                      [16.65, 0.049],
                      [41.406, -9.741],
                      [49.609, -40.039],
                      [49.609, -42.676],
                      [43.53, -64.185],
                      [26.904, -72.07],
                      [15.283, -69.043],
                      [7.593, -60.303],
                      [4.883, -47.461],
                      [7.373, -35.327],
                      [14.429, -26.807],
                      [25.146, -23.779],
                      [33.765, -25.83],
                      [40.527, -31.25],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "9",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [2.327, 3.142],
                      [0, 4.851],
                      [-2.409, 3.044],
                      [-3.972, 0],
                      [-2.572, -3.711],
                      [0, -5.924],
                      [0, 0],
                      [2.555, -1.855],
                      [3.027, 0],
                    ],
                    o: [
                      [-2.328, -3.141],
                      [0, -4.98],
                      [2.409, -3.043],
                      [3.938, 0],
                      [2.571, 3.711],
                      [0, 0],
                      [-1.172, 2.734],
                      [-2.556, 1.855],
                      [-3.906, 0],
                    ],
                    v: [
                      [17.261, -35.962],
                      [13.77, -47.949],
                      [17.383, -59.985],
                      [26.953, -64.551],
                      [36.719, -58.984],
                      [40.576, -44.531],
                      [40.576, -40.918],
                      [34.985, -34.033],
                      [26.611, -31.25],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "9",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "9",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "Y",
      size: 36,
      style: "Regular",
      w: 60.94,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [11.475, -71.094],
                      [0.732, -71.094],
                      [25.244, -26.514],
                      [25.244, 0],
                      [34.619, 0],
                      [34.619, -26.514],
                      [59.131, -71.094],
                      [48.486, -71.094],
                      [29.932, -35.4],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "Y",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "Y",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "e",
      size: 36,
      style: "Regular",
      w: 52.98,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-4.427, 6.804],
                      [0, 0],
                      [2.213, -1.302],
                      [3.19, 0],
                      [2.913, 3.207],
                      [0.13, 5.209],
                      [0, 0],
                      [0, 0],
                      [3.809, 4.525],
                      [6.868, 0],
                      [3.596, -2.327],
                      [2.002, -4.118],
                      [0, -5.241],
                      [0, 0],
                      [-4.492, -4.704],
                      [-7.162, 0],
                    ],
                    o: [
                      [0, 0],
                      [-1.66, 2.148],
                      [-2.214, 1.302],
                      [-4.492, 0],
                      [-2.914, -3.206],
                      [0, 0],
                      [0, 0],
                      [0, -8.43],
                      [-3.809, -4.524],
                      [-4.199, 0],
                      [-3.597, 2.328],
                      [-2.002, 4.118],
                      [0, 0],
                      [0, 7.878],
                      [4.492, 4.704],
                      [8.854, 0],
                    ],
                    v: [
                      [48.682, -9.229],
                      [43.164, -13.525],
                      [37.354, -8.35],
                      [29.248, -6.396],
                      [18.14, -11.206],
                      [13.574, -23.828],
                      [49.365, -23.828],
                      [49.365, -27.588],
                      [43.652, -47.021],
                      [27.637, -53.809],
                      [15.942, -50.317],
                      [7.544, -40.649],
                      [4.541, -26.611],
                      [4.541, -24.951],
                      [11.279, -6.079],
                      [28.76, 0.977],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "e",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [-2.214, -2.522],
                      [-0.261, -4.59],
                      [0, 0],
                      [0, 0],
                      [-2.474, 2.654],
                      [-3.646, 0],
                    ],
                    o: [
                      [2.213, 2.523],
                      [0, 0],
                      [0, 0],
                      [0.586, -4.785],
                      [2.473, -2.653],
                      [3.776, 0],
                    ],
                    v: [
                      [36.621, -42.603],
                      [40.332, -31.934],
                      [40.332, -31.25],
                      [13.867, -31.25],
                      [18.457, -42.407],
                      [27.637, -46.387],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "e",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "e",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "a",
      size: 36,
      style: "Regular",
      w: 54.39,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 4.851],
                      [0, 0],
                      [3.45, 3.044],
                      [6.087, 0],
                      [3.206, -1.432],
                      [1.871, -2.457],
                      [0, -2.637],
                      [0, 0],
                      [-2.181, 1.628],
                      [-3.223, 0],
                      [-1.888, -1.871],
                      [0, -3.157],
                      [0, 0],
                      [0, 0],
                      [4.182, -3.043],
                      [0, -5.501],
                      [-3.337, -2.946],
                      [-5.209, 0],
                      [-4.199, 4.362],
                      [-0.521, -1.041],
                    ],
                    o: [
                      [0, 0],
                      [-1.237, -2.864],
                      [0, 0],
                      [-0.13, -5.338],
                      [-3.451, -3.043],
                      [-3.841, 0],
                      [-3.207, 1.433],
                      [-1.872, 2.458],
                      [0, 0],
                      [0, -2.311],
                      [2.18, -1.627],
                      [3.678, 0],
                      [1.888, 1.872],
                      [0, 0],
                      [0, 0],
                      [-7.585, 0],
                      [-4.183, 3.044],
                      [0, 4.525],
                      [3.336, 2.946],
                      [5.827, 0],
                      [0.325, 2.67],
                      [0, 0],
                    ],
                    v: [
                      [48.926, 0],
                      [48.926, -0.781],
                      [47.07, -12.354],
                      [47.07, -36.67],
                      [41.699, -49.243],
                      [27.393, -53.809],
                      [16.821, -51.66],
                      [9.204, -45.825],
                      [6.396, -38.184],
                      [15.479, -38.184],
                      [18.75, -44.092],
                      [26.855, -46.533],
                      [35.205, -43.726],
                      [38.037, -36.182],
                      [38.037, -32.031],
                      [29.248, -32.031],
                      [11.597, -27.466],
                      [5.322, -14.648],
                      [10.327, -3.442],
                      [23.145, 0.977],
                      [38.184, -5.566],
                      [39.453, 0],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "a",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [1.888, 1.595],
                      [0, 2.832],
                      [-11.068, 0],
                      [0, 0],
                      [0, 0],
                      [2.539, -1.465],
                      [2.832, 0],
                    ],
                    o: [
                      [-1.888, -1.595],
                      [0, -6.477],
                      [0, 0],
                      [0, 0],
                      [-1.14, 2.344],
                      [-2.539, 1.465],
                      [-2.962, 0],
                    ],
                    v: [
                      [17.188, -9.277],
                      [14.355, -15.918],
                      [30.957, -25.635],
                      [38.037, -25.635],
                      [38.037, -14.795],
                      [32.52, -9.082],
                      [24.463, -6.885],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "a",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "a",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
    {
      ch: "r",
      size: 36,
      style: "Regular",
      w: 33.84,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [1.758, 0],
                      [2.962, -4.72],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-5.925, 0],
                      [-1.367, -0.227],
                    ],
                    o: [
                      [-0.912, -0.455],
                      [-5.437, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.116, -5.045],
                      [1.595, 0],
                      [0, 0],
                    ],
                    v: [
                      [32.373, -53.125],
                      [28.369, -53.809],
                      [15.771, -46.729],
                      [15.625, -52.832],
                      [6.836, -52.832],
                      [6.836, 0],
                      [15.869, 0],
                      [15.869, -37.5],
                      [27.93, -45.068],
                      [32.373, -44.727],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "r",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "r",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Roboto",
    },
  ],
};
