export default function TextBlock({
  height,
  textColor,
  align,
  subtitle,
  title,
  text,
  fade,
}) {
  return (
    <div
      className={`text-wrapper ${align}`}
      style={height ? { height: height, animationDelay: "100ms" } : {}}
    >
      <div>
        {subtitle && (
          <p
            className="text-block-subtitle fadein"
            style={
              textColor
                ? {
                    textAlign: align,
                    color: textColor,
                    animationDelay: "100ms",
                  }
                : { textAlign: align, animationDelay: "0ms" }
            }
          >
            {subtitle}
          </p>
        )}
        <h3
          className="text-block-title fadein"
          style={{ textAlign: align, animationDelay: "300ms" }}
        >
          {title}
        </h3>
        <p
          className="text-block fadein"
          style={
            textColor
              ? { textAlign: align, color: textColor, animationDelay: "500ms" }
              : { textAlign: align, animationDelay: "500ms" }
          }
        >
          {text}
        </p>
      </div>
    </div>
  );
}
