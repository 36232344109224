import { useState, useEffect, useRef } from "react";

import Modal from "./components/Modal";
import FullModal from "./components/FullModal";
import { isIE } from "react-device-detect";
import Lottie from "lottie-react";
import animationData from "./assets/lottie/publish-data.json";
import { default as animationData2 } from "./assets/lottie/graph-data.js";
import { default as animationData3 } from "./assets/lottie/toolUI-data.js";

import { useMediaQuery } from "react-responsive";
//import ActiveSection from "./components/ActiveSection";
import CloseButton from "./components/CloseButton";
import Navigation from "./components/Navigation";
import Flip from "./components/Flip";
import TabbedBlock from "./components/TabbedBlock";
import TextBlock from "./components/TextBlock";
import Showcase from "./blocks/Showcase";
import MobileShowcase from "./blocks/MobileShowcase";
import ContactUs from "./blocks/ContactUs";
import Pricing from "./components/Pricing";
import MobileMenu from "./components/MobileMenu";
import MobilePricing from "./blocks/MobilePricing";
import AboutUs from "./blocks/AboutUs";

const VerticalApp = ({ func, d, setD, isOpen, setFullOpen, tab, setTab, setIsOpen, setMobileMenu }) => {
	const [showVideo, setShowVideo] = useState(false);
	const [automation, setAutomation] = useState(false);
	const [analytics, setAnalytics] = useState(false);
	const [ui, setUi] = useState(false);
	const overview = document.getElementById("overview-video");
	const emulator = document.getElementById("emulator-video");
	const simulator = document.getElementById("simulator-video");
	useEffect(() => {
		if (d === 2) {
			if (!showVideo) {
				setTimeout(() => {
					setShowVideo(true);
				}, 1300);
			} else {
				if (tab === "overview") {
					setTimeout(() => {
						if (overview) {
							overview.play();
						}
					}, 300);
				} else if (tab === "emulator") {
					setTimeout(() => {
						if (emulator) {
							emulator.play();
						}
					}, 300);
				} else if (tab === "simulator") {
					setTimeout(() => {
						if (simulator) {
							simulator.play();
						}
					}, 300);
				}
			}
		} else if (d === 3 && !automation) {
			setTimeout(() => {
				setAutomation(true);
			}, 500);
		} else if (d === 4 && !analytics) {
			setTimeout(() => {
				setAnalytics(true);
			}, 500);
		} else if (d === 5 && !ui) {
			setTimeout(() => {
				setUi(true);
			}, 500);
		}
		if (d !== 2) {
			setShowVideo(false);
		}
	}, [d, automation, analytics, ui, showVideo]);

	return (
		<FullWrap>
			<SectionWrap>
				<div className="block intro">
					<div
						className="inner"
						style={{
							flexDirection: "column",
							backgroundColor: "transparent",
						}}>
						<h3 class="bold hero-title">
							<Flip />
							&nbsp; Tool for
						</h3>
						<h3 class="bold hero-title">Creating Dynamic Content</h3>
						<p class="hero-text" style={{ textAlign: "center" }}>
							Engage your customers with dynamic content that resolves problems
							<br />
							and deflects unnecessary calls.
						</p>
						<button
							className="btn hero-button"
							onClick={() => {
								setFullOpen(true);
							}}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<p style={{ margin: "4px 0 0 0", padding: 0 }}>Play Video</p>
							<svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="28.688" viewBox="0 0 22.5 28.688">
								<path d="M6.75,3.656V32.344L29.25,18Z" transform="translate(-6.75 -3.656)" />
							</svg>
						</button>
					</div>
				</div>
			</SectionWrap>
			<SectionWrap>
				<div className="row">
					<div className="half-block end-aligned" style={{ backgroundColor: "white" }}>
						<div className="half-inner ">
							{d === 2 && (
								<>
									{tab === "overview" ? (
										<>
											{showVideo ? (
												<video id="overview-video" src="./assets/video/sim1.mp4" alt="" muted autoPlay loop playsInline></video>
											) : (
												<img src="./assets/images/sim1.jpg" className="video-placeholder" alt="" />
											)}
										</>
									) : tab === "emulator" ? (
										<>
											{showVideo ? (
												<video id="emulator-video" src="./assets/video/webostv-emulator.mp4" alt="" muted autoPlay loop playsInline></video>
											) : (
												<img src="./assets/images/sim2.jpg" className="video-placeholder" alt="" />
											)}
										</>
									) : (
										<>
											{showVideo ? (
												<video id="simulator-video" src="./assets/video/sim3.mp4" alt="" muted autoPlay loop playsInline></video>
											) : (
												<img src="./assets/images/sim3.jpg" className="video-placeholder" alt="" />
											)}
										</>
									)}
								</>
							)}
						</div>
					</div>
					<div className="half-block start-aligned" style={{ backgroundColor: "#023458" }}>
						<div className="half-inner column">
							{d === 2 && (
								<div className="text-wrapper">
									<div className="text-wrapper" style={{ height: "40px" }}>
										<TabbedBlock tab={tab} setTab={setTab} />
									</div>

									{tab === "overview" ? (
										<TextBlock
											fade={true}
											height="60%"
											textColor="white"
											align="center"
											title="HUB FOR ALL YOUR CONTENT"
											text="A visual display of your product along with links that connect to your existing content. Let your customers bypass the search-guesswork by simply clicking to bring up all related content."
										/>
									) : tab === "emulator" ? (
										<TextBlock
											fade={true}
											height="60%"
											textColor="white"
											align="center"
											title="REPLICATE YOUR DEVICE"
											text="Emulators allow your customers to experience your product as if
						they are holding it in their hands. It's a powerful tool for both
						pre-sale marketing and post-sale support."
										/>
									) : tab === "simulator" ? (
										<TextBlock
											fade={true}
											height="60%"
											textColor="white"
											align="center"
											title="VIRTUAL GUIDE"
											text="Unlike long and outdated FAQ support articles, simulators guide the viewer
						with step-by-step demonstrations and an interactive UI."
										/>
									) : (
										""
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</SectionWrap>
			<SectionWrap>
				<div className="row">
					<div className="half-block" style={{ backgroundColor: "#F7F7F7" }}>
						<div className="half-inner ">
							{d === 3 && (
								<div className="fadein">
									<Lottie
										animationData={animationData}
										loop={true}
										autoPlay={true}
										rendererSettings={"xMidYMid slice"}
										style={{
											height: 250,
											width: "auto",
										}}
									/>
								</div>
							)}
						</div>
					</div>
					<div className="half-block" style={{ backgroundColor: "#F7F7F7" }}>
						<div
							className="half-inner"
							// style={{ alignItems: "flex-start" }}
						>
							{d === 3 && (
								<div className="fadein" style={{ animationDelay: "300ms" }}>
									<TextBlock
										align="center"
										subtitle="Smart Automation"
										title="AUTO-CONVERT CONTENT"
										text="Make a single content, then let the Vikarius program convert your
          content into multiple formats with a click of a button. Smart automation to eliminate tedious tasks without sacrificing function."
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</SectionWrap>
			<SectionWrap>
				<div className="row">
					<div className="half-block" style={{ backgroundColor: "white" }}>
						<div className="half-inner ">
							{d === 4 && (
								<div className="fadein">
									<Lottie
										animationData={animationData2}
										loop={true}
										autoPlay={true}
										rendererSettings={"xMidYMid slice"}
										style={{
											height: 350,
											width: "auto",
										}}
									/>
								</div>
							)}
						</div>
					</div>
					<div className="half-block" style={{ backgroundColor: "#023458" }}>
						<div
							className="half-inner"
							// style={{ alignItems: "flex-start" }}
						>
							{d === 4 && (
								<div className="fadein" style={{ animationDelay: "300ms" }}>
									<TextBlock
										align="center"
										textColor="white"
										subtitle="Deep Analytics"
										title="MANAGE, CONTROL, &amp; PROMOTE"
										text="Use in-depth analytics to see where your customers thrive and
						  struggle. It’s a powerful tool that decrypts your customers'
						  behavior so that you don't second guess your content."
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</SectionWrap>
			<SectionWrap>
				<div className="row">
					<div className="half-block" style={{ backgroundColor: "#F7F7F7" }}>
						<div className="half-inner ">
							{d === 5 && (
								<div className="fadein">
									<Lottie
										animationData={animationData3}
										loop={true}
										autoPlay={true}
										rendererSettings={"xMidYMid slice"}
										style={{
											height: 400,
											width: "auto",
										}}
									/>
								</div>
							)}
						</div>
					</div>
					<div className="half-block " style={{ backgroundColor: "#F7F7F7" }}>
						<div
							className="half-inner"
							// style={{ alignItems: "flex-start" }}
						>
							{d === 5 && (
								<div className="fadein" style={{ animationDelay: "300ms" }}>
									<TextBlock
										align="center"
										subtitle="Easy to use"
										title="VERSATILE UI"
										text="An intuitive interface that allows content creators to focus on
						  creating, not learning. Familiar buttons and well-thought-out UI
						  makes using the tool a second nature."
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</SectionWrap>
			<SectionWrap bgColor={"#199cdc"}>
				<div className="row" style={{ justifyContent: "center", height: "100%" }}>
					<AboutUs func={func} />
				</div>
			</SectionWrap>
			<SectionWrap>
				<div className="block showcase-column">
					<MobileShowcase setIsOpen={setIsOpen} />
				</div>
			</SectionWrap>
			<SectionWrap bgColor={"#f2f2f2"}>
				<div className="block column contact">
					<div className="inner column">
						<ContactUs />
					</div>
					<div className="footer">
						<p>Copyright © Vikarius. 2021</p>
					</div>
				</div>
			</SectionWrap>
		</FullWrap>
	);
};

const HorizontalApp = ({ func, d, setD, isOpen, setFullOpen, tab, setTab, setIsOpen }) => {
	const [showVideo, setShowVideo] = useState(false);
	const [automation, setAutomation] = useState(false);
	const [analytics, setAnalytics] = useState(false);
	const [ui, setUi] = useState(false);
	const overview = document.getElementById("overview-video");
	const emulator = document.getElementById("emulator-video");
	const simulator = document.getElementById("simulator-video");
	useEffect(() => {
		if (d === 2) {
			if (!showVideo) {
				setTimeout(() => {
					setShowVideo(true);
				}, 1300);
			} else {
				if (tab === "overview") {
					setTimeout(() => {
						if (overview) {
							overview.play();
						}
					}, 300);
				} else if (tab === "emulator") {
					setTimeout(() => {
						if (emulator) {
							emulator.play();
						}
					}, 300);
				} else if (tab === "simulator") {
					setTimeout(() => {
						if (simulator) {
							simulator.play();
						}
					}, 300);
				}
			}
		} else if (d === 3 && !automation) {
			setTimeout(() => {
				setAutomation(true);
			}, 500);
		} else if (d === 4 && !analytics) {
			setTimeout(() => {
				setAnalytics(true);
			}, 500);
		} else if (d === 5 && !ui) {
			setTimeout(() => {
				setUi(true);
			}, 500);
		}
		if (d !== 2) {
			setShowVideo(false);
		}
	}, [d, automation, analytics, ui, showVideo]);

	return (
		<FullWrap>
			<SectionWrap>
				<div className="block intro">
					<div
						className="inner"
						style={{
							flexDirection: "column",
							backgroundColor: "transparent",
						}}>
						<h3 class="bold hero-title">
							<Flip />
							&nbsp; Tool for
						</h3>
						<h3 class="bold hero-title">Creating Dynamic Content</h3>
						<p class="hero-text" style={{ textAlign: "center" }}>
							Engage your customers with dynamic content that resolves problems
							<br />
							and deflects unnecessary calls.
						</p>
						<button
							className="btn hero-button"
							onClick={() => {
								setFullOpen(true);
							}}
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<p style={{ margin: "4px 0 0 0", padding: 0 }}>Play Video</p>
							<svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="28.688" viewBox="0 0 22.5 28.688">
								<path d="M6.75,3.656V32.344L29.25,18Z" transform="translate(-6.75 -3.656)" />
							</svg>
						</button>
					</div>
				</div>
			</SectionWrap>
			<SectionWrap>
				<div className="row">
					<div className="half-block end-aligned" style={{ backgroundColor: "white" }}>
						<div className="half-inner ">
							{d === 2 && (
								<>
									{tab === "overview" ? (
										<>
											{showVideo ? (
												<video id="overview-video" src="./assets/video/sim1.mp4" alt="" muted autoPlay loop playsInline></video>
											) : (
												<img src="./assets/images/sim1.jpg" className="video-placeholder" alt="" />
											)}
										</>
									) : tab === "emulator" ? (
										<>
											{showVideo ? (
												<video id="emulator-video" src="./assets/video/webostv-emulator.mp4" alt="" muted autoPlay loop playsInline></video>
											) : (
												<img src="./assets/images/sim2.jpg" className="video-placeholder" alt="" />
											)}
										</>
									) : (
										<>
											{showVideo ? (
												<video id="simulator-video" src="./assets/video/sim3.mp4" alt="" muted autoPlay loop playsInline></video>
											) : (
												<img src="./assets/images/sim3.jpg" className="video-placeholder" alt="" />
											)}
										</>
									)}
								</>
							)}
						</div>
					</div>
					<div className="half-block start-aligned" style={{ backgroundColor: "#023458" }}>
						<div className="half-inner column">
							{d === 2 && (
								<div className="text-wrapper">
									<div className="text-wrapper" style={{ height: "40px" }}>
										<TabbedBlock tab={tab} setTab={setTab} />
									</div>

									{tab === "overview" ? (
										<TextBlock
											fade={true}
											height="40%"
											textColor="white"
											align="left"
											title="HUB FOR ALL YOUR CONTENT"
											text="A visual display of your product along with links that connect to your existing content. Let your customers bypass the search-guesswork by simply clicking to bring up all related content."
										/>
									) : tab === "emulator" ? (
										<TextBlock
											fade={true}
											height="40%"
											textColor="white"
											align="left"
											title="REPLICATE YOUR DEVICE"
											text="Emulators allow your customers to experience your product as if
						they are holding it in their hands. It's a powerful tool for both
						pre-sale marketing and post-sale support."
										/>
									) : tab === "simulator" ? (
										<TextBlock
											fade={true}
											height="40%"
											textColor="white"
											align="left"
											title="VIRTUAL GUIDE"
											text="Unlike long outdated FAQ support articles, simulators guide the viewer with step-by-step demonstrations on an interactive UI. Simulators are also a great tool to train your agents, much more efficient than static PPT documents."
										/>
									) : (
										""
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</SectionWrap>
			<SectionWrap>
				<div className="row">
					<div className="half-block end-aligned" style={{ backgroundColor: "#F7F7F7" }}>
						<div className="half-inner ">
							{d === 3 && (
								<div className="fadein" style={{ animationDelay: "300ms" }}>
									<TextBlock
										align="right"
										subtitle="Smart Automation"
										title="AUTO-CONVERT CONTENT"
										text="Make a single content, then let the Vikarius program convert your
          content into multiple formats with a click of a button. Smart automation to eliminate tedious tasks without sacrificing function."
									/>
								</div>
							)}
						</div>
					</div>
					<div className="half-block start-aligned" style={{ backgroundColor: "#F7F7F7" }}>
						<div className="half-inner ">
							{d === 3 && (
								<div className="fadein">
									<Lottie
										animationData={animationData}
										loop={true}
										autoPlay={true}
										rendererSettings={"xMidYMid slice"}
										style={{
											height: 400,
											width: "auto",
										}}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</SectionWrap>
			<SectionWrap>
				<div className="row">
					<div className="half-block end-aligned" style={{ backgroundColor: "white" }}>
						<div className="half-inner ">
							{d === 4 && (
								<div className="fadein">
									<Lottie
										animationData={animationData2}
										loop={true}
										autoPlay={true}
										rendererSettings={"xMidYMid slice"}
										style={{
											height: 600,
											width: "auto",
										}}
									/>
								</div>
							)}
						</div>
					</div>
					<div className="half-block start-aligned" style={{ backgroundColor: "#023458" }}>
						<div className="half-inner ">
							{d === 4 && (
								<div className="fadein" style={{ animationDelay: "300ms" }}>
									<TextBlock
										align="left"
										subtitle="Deep Analytics"
										textColor="white"
										title="MANAGE, CONTROL, &amp; PROMOTE"
										text="Use in-depth analytics to see where your customers thrive and
						  struggle. It’s a powerful tool that decrypts your customers'
						  behavior so that you don't second guess your content."
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</SectionWrap>
			<SectionWrap>
				<div className="row">
					<div className="half-block end-aligned" style={{ backgroundColor: "#F7F7F7" }}>
						<div className="half-inner ">
							{d === 5 && (
								<div className="fadein" style={{ animationDelay: "300ms" }}>
									<TextBlock
										align="right"
										subtitle="Easy to use"
										title="VERSATILE UI"
										text="An intuitive interface that allows content creators to focus on
						  creating, not learning. Familiar buttons and well-thought-out UI
						  makes using the tool a second nature."
									/>
								</div>
							)}
						</div>
					</div>
					<div className="half-block start-aligned" style={{ backgroundColor: "#F7F7F7" }}>
						<div className="half-inner ">
							{d === 5 && (
								<div className="fadein">
									<Lottie
										animationData={animationData3}
										loop={true}
										autoPlay={true}
										rendererSettings={"xMidYMid slice"}
										style={{
											height: 600,
											width: "auto",
										}}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
			</SectionWrap>
			<SectionWrap bgColor={"#199cdc"}>
				<div className="row" style={{ justifyContent: "center", height: "100%" }}>
					<AboutUs func={func} />
				</div>
			</SectionWrap>
			<SectionWrap bgColor={"#F7F7F7"}>
				<div className="block showcase-column">
					<Showcase setIsOpen={setIsOpen} />
				</div>
			</SectionWrap>
			<SectionWrap bgColor={"#f2f2f2"}>
				<div className="block column contact">
					<div className="inner column">
						<ContactUs />
					</div>
					<div className="footer">
						<p>Copyright © Vikarius. 2021</p>
					</div>
				</div>
			</SectionWrap>
		</FullWrap>
	);
};

function App() {
	const [tab, setTab] = useState("overview");
	const [isOpen, setIsOpen] = useState(false);
	const [fullOpen, setFullOpen] = useState(false);
	const [d, setD] = useState(1);
	const [mobileMenu, setMobileMenu] = useState(false);
	const [start, setStart] = useState(true);
	const [height, setHeight] = useState(0);
	const handleResize = () => {
		setHeight(window.innerHeight);
	};
	useEffect(() => {
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	const func = {
		moveTo: (e) => setD(e),
	};
	useEffect(() => {
		if (d !== 0 && start) {
			setStart(false);
		}
	}, [d]);
	const isDesktopOrLaptop = useMediaQuery({
		query: "(min-width: 961px)",
	});
	const isMed = useMediaQuery({
		query: "(max-width: 960px)",
	});
	const isSmall = useMediaQuery({
		query: "(max-width: 800px)",
	});
	const touchRef = useRef(null);
	const touchMoveRef = useRef(null);
	const handleTouchStart = (event) => {
		touchRef.current = event.touches[0].clientY;
	};

	const handleTouchMove = (event) => {
		if (touchRef.current === null) return;
		touchMoveRef.current = event.touches[0].clientY;
	};

	const handleTouchEnd = () => {
		const deltaY = touchMoveRef.current !== null && touchRef.current !== null ? touchMoveRef.current - touchRef.current : null;
		if (deltaY !== null && deltaY > 100 && d > 1) {
			touchMoveRef.current = null;
			touchRef.current = null;
			setD((p) => p - 1);
		} else if (deltaY !== null && deltaY < -100 && d < 8) {
			touchMoveRef.current = null;
			touchRef.current = null;
			setD((p) => p + 1);
		} else {
			touchMoveRef.current = null;
			touchRef.current = null;
		}
	};

	return (
		<>
			<div
				className="fullpage-wrapper"
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					"--height": height,
				}}>
				{d > 1 ? (
					<div
						className={d > 0 ? "backtotop fadeinup" : !start ? "backtotop fadeout" : "backtotop hidden"}
						onClick={() => {
							func.moveTo(1);
						}}>
						<svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="KeyboardArrowUpIcon" style={{ width: "80%", height: "auto" }}>
							<path d="M7.41 15.41 12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path>
						</svg>
					</div>
				) : (
					<img
						src="./assets/images/mouse.png"
						alt=""
						className="mouse-scroll"
						onClick={() => {
							func.moveTo(2);
						}}
					/>
				)}
			</div>
			<div
				className="App"
				style={{ "--loc": d - 1, "--height": height }}
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onTouchEnd={handleTouchEnd}
				onWheel={(e) => {
					if (e.deltaY > 0 && d < 8) {
						setD((p) => p + 1);
					} else if (e.deltaY < 0 && d > 1) {
						setD((p) => p - 1);
					}
				}}>
				{/* <ActiveSection d={d} func={func} /> */}
				<Modal
					onTouchStart={(e) => e.stopPropagation()}
					onClose={() => {
						setIsOpen(false);
					}}
					open={isOpen}>
					{isMed || isSmall ? <MobilePricing setIsOpen={setIsOpen} /> : <Pricing setIsOpen={setIsOpen} />}
				</Modal>
				<FullModal
					onClose={() => {
						setFullOpen(false);
					}}
					open={fullOpen}>
					<CloseButton func={setFullOpen} color="white" />
					<video src="./assets/video/vicarious-intro.mp4" id="video" controls playsInline></video>
				</FullModal>
				<MobileMenu
					onClose={() => {
						setMobileMenu(false);
					}}
					open={mobileMenu}>
					<div
						style={{
							height: "100%",
							width: "100%",
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}>
						<CloseButton func={setMobileMenu} color="white" />
						<p
							className="mobile-menu-text"
							style={{ width: "100%", textAlign: "center", cursor: "pointer" }}
							onClick={() => {
								func.moveTo(1);
								setMobileMenu(false);
							}}>
							Home
						</p>
						<p
							className="mobile-menu-text"
							style={{ width: "100%", textAlign: "center", cursor: "pointer" }}
							onClick={() => {
								setMobileMenu(false);
								setIsOpen(true);
							}}>
							Pricing
						</p>
						<p
							className="mobile-menu-text"
							style={{ width: "100%", textAlign: "center", cursor: "pointer" }}
							onClick={() => {
								func.moveTo(7);
								setMobileMenu(false);
							}}>
							Showcase
						</p>
						<p
							className="mobile-menu-text"
							style={{ width: "100%", textAlign: "center", cursor: "pointer" }}
							onClick={() => {
								func.moveTo(8);
								setMobileMenu(false);
							}}>
							Contact
						</p>
						<p
							className="mobile-menu-text"
							style={{ width: "100%", textAlign: "center", cursor: "pointer" }}
							onClick={() => {
								window.location = "https://vkrscontent.com/login";
								setMobileMenu(false);
							}}>
							Sign in
						</p>
					</div>
				</MobileMenu>

				<Navigation setIsOpen={setIsOpen} setFullOpen={setFullOpen} func={func} d={d} isOpen={isOpen} setMobileMenu={setMobileMenu} />

				{isDesktopOrLaptop && <HorizontalApp func={func} d={d} setD={setD} isOpen={isOpen} setFullOpen={setFullOpen} tab={tab} setTab={setTab} setIsOpen={setIsOpen} />}
				{(isMed || isSmall) && (
					<>
						<img src="./assets/images/vkrslogo-white.svg" className="mobile-logo" alt="Vikarius logo" id="mobile-logo" />
					</>
				)}
				{isMed || isSmall || d > 0 ? (
					<svg
						className="menu-icon-mobile"
						xmlns="http://www.w3.org/2000/svg"
						width="27"
						height="18"
						viewBox="0 0 27 18"
						style={d > 0 && d !== 5 ? { fill: "black" } : {}}
						onClick={() => {
							setMobileMenu(true);
						}}>
						<path d="M4.5,27h27V24H4.5Zm0-7.5h27v-3H4.5ZM4.5,9v3h27V9Z" transform="translate(-4.5 -9)" />
					</svg>
				) : null}
				{(isSmall || isMed) && (
					<VerticalApp func={func} d={d} setD={setD} isOpen={isOpen} setFullOpen={setFullOpen} tab={tab} setTab={setTab} setIsOpen={setIsOpen} setMobileMenu={setMobileMenu} />
				)}
			</div>
		</>
	);
}

const FullWrap = ({ children }) => (
	<div
		id="fullpage"
		className="fullpage-wrapper"
		style={{
			height: "100%",
			position: "relative",
			touchAction: "none",
			transform: "translate3d(0px, 0px, 0px)",
		}}>
		{children}
	</div>
);

const SectionWrap = ({ children, bgColor }) => {
	return (
		<div className="section fp-section active fp-table fp-completely" style={bgColor ? { backgroundColor: bgColor } : {}}>
			<div className="fp-tableCell" style={{ height: "100%" }}>
				{children}
			</div>
		</div>
	);
};
export default App;
