import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Portal from "./Portal";
import CloseButton from "./CloseButton";

const Backdrop = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 20;
	background-color: rgba(51, 51, 51, 0.3);
	backdrop-filter: blur(1px);
	opacity: 0;
	transition: all 100ms cubic-bezier(0.4, 0, 0.2, 1);
	transition-delay: 200ms;
	display: flex;
	align-items: center;
	justify-content: center;

	& .modal-content {
		transform: translateY(100px);
		transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
		opacity: 0;
	}

	&.active {
		transition-duration: 250ms;
		transition-delay: 0ms;
		opacity: 1;

		& .modal-content {
			transform: translateY(0);
			opacity: 1;
			transition-delay: 150ms;
			transition-duration: 350ms;
		}
	}
`;

const Content = styled.div`
	position: relative;
	box-sizing: border-box;
	height: 100%;
	width: 100%;
	box-shadow: none;
	background-color: black;
	border-radius: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export default function MobileMenu(props) {
	const [active, setActive] = useState(false);
	const { open, onClose, locked } = props;
	const backdrop = useRef(null);
	useEffect(() => {
		const video = document.getElementById("video");
		if (video) {
			video.play();
		}
		const { current } = backdrop;

		const transitionEnd = () => setActive(open);

		const keyHandler = (e) => !locked && [27].indexOf(e.which) >= 0 && onClose();

		const clickHandler = (e) => !locked && e.target === current && onClose();

		if (current) {
			current.addEventListener("transitionend", transitionEnd);
			current.addEventListener("click", clickHandler);
			window.addEventListener("keyup", keyHandler);
		}

		if (open) {
			window.setTimeout(() => {
				document.activeElement.blur();
				setActive(open);
				document.querySelector("#root").setAttribute("inert", "true");
			}, 10);
		}

		return () => {
			if (current) {
				current.removeEventListener("transitionend", transitionEnd);
				current.removeEventListener("click", clickHandler);
			}

			document.querySelector("#root").removeAttribute("inert");
			window.removeEventListener("keyup", keyHandler);
		};
	}, [open, locked, onClose]);

	return (
		<>
			{(open || active) && (
				<Portal className="modal-portal">
					<Backdrop ref={backdrop} className={active && open && "active"}>
						<Content className="modal-content">
							<CloseButton func={props.setIsOpen}></CloseButton>
							{props.children}
						</Content>
					</Backdrop>
				</Portal>
			)}
		</>
	);
}
