import { useEffect, useState } from "react";

export default function TabbedBlock({ tab, setTab }) {
	const [hovering, setHovering] = useState("none");

	useEffect(() => {
		let mydiv = null;
		const target = document.getElementById("tsub1");
		if (tab === "overview") {
			mydiv = document.getElementById("overview-tab");
		} else if (tab === "emulator") {
			mydiv = document.getElementById("emulator-tab");
		} else if (tab === "simulator") {
			mydiv = document.getElementById("simulator-tab");
		}
		if (mydiv) {
			target.style.opacity = 0;
			setTimeout(() => {
				target.style.left = `${mydiv.getBoundingClientRect().left + window.pageXOffset}px`;
				target.style.top = `${mydiv.getBoundingClientRect().top + window.pageYOffset}px`;
				target.style.width = `${mydiv.getBoundingClientRect().width}px`;
				target.style.height = `${mydiv.getBoundingClientRect().height}px`;
				target.style.borderColor = "orange";
				target.style.transform = "none";
				setTimeout(() => {
					target.style.opacity = 1;
				}, 250);
			}, 1000);
		}
	}, []);

	useEffect(() => {
		let mydiv = null;
		const target = document.getElementById("tsub1");
		if (tab === "overview") {
			mydiv = document.getElementById("overview-tab");
		} else if (tab === "emulator") {
			mydiv = document.getElementById("emulator-tab");
		} else if (tab === "simulator") {
			mydiv = document.getElementById("simulator-tab");
		}
		if (mydiv) {
			target.style.left = `${mydiv.getBoundingClientRect().left + window.pageXOffset}px`;
			target.style.top = `${mydiv.getBoundingClientRect().top + window.pageYOffset}px`;
			target.style.width = `${mydiv.getBoundingClientRect().width}px`;
			target.style.height = `${mydiv.getBoundingClientRect().height}px`;
			target.style.borderColor = "orange";
			target.style.transform = "none";
		}
	}, [tab]);

	// useEffect(() => {
	//   const active = document.getElementById("tabbed");
	//   const target = document.querySelector(".targetsub");
	//   const links = document.querySelectorAll(".tabbed-section li");

	//   const left = active.getBoundingClientRect().left + window.pageXOffset;
	//   const top = active.getBoundingClientRect().top + window.pageYOffset;
	//   const width = active.getBoundingClientRect().width;
	//   const height = ;
	//   setTimeout(() => {
	//     target.style.left = `${left}px`;
	//     target.style.top = `${top}px`;
	//     target.style.width = `${width}px`;
	//     target.style.height = `${height}px`;
	//     target.style.borderColor = "orange";
	//     target.style.transform = "none";
	//   }, 1500);

	//   function mouseenterFunc() {
	//     if (!this.classList.contains("accent-tab")) {
	//       for (let i = 0; i < links.length; i++) {
	//         if (links[i].classList.contains("accent-tab")) {
	//           links[i].classList.remove("accent-tab");
	//         }
	//         links[i].style.opacity = "0.75";
	//       }

	//       this.classList.add("accent-tab");
	//       this.style.opacity = "1";

	//       const width = this.getBoundingClientRect().width;
	//       const height = this.getBoundingClientRect().height;
	//       const left = this.getBoundingClientRect().left + window.pageXOffset;
	//       const top = this.getBoundingClientRect().top + window.pageYOffset;

	//       target.style.width = `${width}px`;
	//       target.style.height = `${height}px`;
	//       target.style.left = `${left}px`;
	//       target.style.top = `${top}px`;
	//       target.style.borderColor = "orange";
	//       target.style.transform = "none";
	//     }
	//   }

	//   for (let i = 0; i < links.length; i++) {
	//     links[i].addEventListener("mouseenter", mouseenterFunc);
	//   }

	//   function resizeFunc() {
	//     const active = document.getElementById("tabbed");
	//     if (active) {
	//       const left = active.getBoundingClientRect().left + window.pageXOffset;
	//       const top = active.getBoundingClientRect().top + window.pageYOffset;

	//       target.style.left = `${left}px`;
	//       target.style.top = `${top}px`;
	//     }
	//   }

	//   window.addEventListener("resize", resizeFunc);
	//   return () => {
	//     window.removeEventListener("resize", resizeFunc);
	//   };
	// }, []);
	return (
		<div
			className="text-inner-wrap"
			onMouseEnter={() => {
				setHovering(true);
			}}
			onMouseLeave={() => {
				setHovering(false);
			}}>
			<ul className="tabbed-section" id="tabbed">
				<li
					id="overview-tab"
					className={tab === "overview" ? "accent-tab" : ""}
					onClick={() => {
						setTab("overview");
					}}>
					Device Overview
				</li>
				<li
					id="emulator-tab"
					className={tab === "emulator" ? "accent-tab" : ""}
					onClick={() => {
						setTab("emulator");
					}}>
					Emulator
				</li>
				<li
					id="simulator-tab"
					className={tab === "simulator" ? "accent-tab" : ""}
					onClick={() => {
						setTab("simulator");
					}}>
					Simulator
				</li>
			</ul>
			<div className="targetsub" id="tsub1"></div>
			<div className="targetsub" id="tsub2"></div>
		</div>
	);
}
