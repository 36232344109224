import React from "react";

export default function Showcase({ setIsOpen }) {
	return (
		<>
			<div className="showcase column">
				<h3 className="row-title" style={{ marginTop: "20px" }}>
					Showcase
				</h3>
				<div className="clients" style={{ justifyContent: "center" }}>
					<div className="clients-inner" style={{ maxWidth: "1600px", margin: 0 }}>
						<img src="./assets/images/samsung.svg" alt="" id="img-block" />
						<img src="./assets/images/lg.svg" alt="" />
						<img src="./assets/images/webostv.svg" alt="" id="demo-block" />
					</div>
				</div>
				<div className="demo-row">
					<div className="demo-card">
						<a href="https://www.samsung.com/us/support/simulators/galaxy-s23/?mode=Overview" target="_blank" rel="noreferrer">
							<div id="showcase-1" />
							<p className="demo-title">S23</p>
						</a>
					</div>
					<div className="demo-card">
						<a href="https://www.samsung.com/us/support/simulators/family-hub/?mode=Overview" target="_blank" rel="noreferrer">
							<div id="showcase-2" />
							<p className="demo-title">Smart Hub Fridge</p>
						</a>
					</div>
					<div className="demo-card">
						<a href="https://www.samsung.com/us/support/simulators/smart-tv-simulator/?mode=Overview" target="_blank" rel="noreferrer">
							<div id="showcase-3" />
							<p className="demo-title">Samsung Smart TV</p>
						</a>
					</div>
					<div className="demo-card">
						<a href="https://vkrscontent.com/webostv" target="_blank" rel="noreferrer">
							<div id="showcase-4" />
							<p className="demo-title">webOStv</p>
						</a>
					</div>
				</div>

				<button className="btn" style={{ margin: "30px 0", width: "max-content" }} onClick={() => setIsOpen(true)}>
					View Pricing
				</button>
			</div>
		</>
	);
}
