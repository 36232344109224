import { useState } from "react";
import axios from "axios";

export default function ContactUs() {
	const [error, setError] = useState([]);
	const [success, setSuccess] = useState(false);
	const [form, setForm] = useState({
		name: "",
		email: "",
		message: "",
		phone: "",
	});
	function sendForm(e) {
		e.preventDefault();
		if (success) {
			setSuccess(false);
		}
		if (error.length > 0) {
			setError([]);
		}
		if (!form.name || !form.email || !form.message) {
			if (!form.name) {
				setError((p) => {
					return [...p, "name"];
				});
			}
			if (!form.email) {
				setError((p) => {
					return [...p, "email"];
				});
			}
			if (!form.message) {
				setError((p) => {
					return [...p, "message"];
				});
			}
		} else {
			const send = {
				name: form.name,
				email: form.email,
				message: form.message,
				phone: form.phone,
			};
			axios
				.post("https://sx8nzmkyy4.execute-api.us-east-1.amazonaws.com/v1/mail", send)
				.then((r) => {
					setError([]);
					setSuccess(true);
				})
				.catch((er) => {
					setError((p) => {
						return ["There was a problem sending your message."];
					});
				});
		}
	}
	return (
		<>
			<div className="block-header">
				<h3 className="row-title">Contact</h3>
				<p className="row-description" style={{ marginBottom: "40px" }}>
					Since 2005, we have been helping customers
					<br /> create exceptional content. Find out what we can do for you.
				</p>
			</div>
			<form id="form" className="clearfix">
				<div className="form-inline">
					<div className="form-group input">
						<input
							type="text"
							required
							name="input"
							placeholder="Name"
							id="name-input"
							onChange={(e) => {
								setForm((p) => {
									p = { ...p, name: e.target.value };
									return p;
								});
							}}
						/>
						<i className="bar" />
					</div>
					<div className="form-group input" style={{ float: "right" }}>
						<input
							type="email"
							required
							name="email"
							placeholder="Email"
							id="email-input"
							onChange={(e) => {
								setForm((p) => {
									p = { ...p, email: e.target.value };
									return p;
								});
							}}
						/>
						<i className="bar" />
					</div>
					<input
						name="phonenumber"
						className="form-front"
						tabIndex={-1}
						autoComplete="off"
						id="phonenumber-input"
						onChange={(e) => {
							setForm((p) => {
								p = { ...p, phone: e.target.value };
								return p;
							});
						}}
					/>
				</div>
				<div className="form-group">
					<textarea
						required="required"
						placeholder="Message"
						id="message-input"
						defaultValue={""}
						onChange={(e) => {
							setForm((p) => {
								p = { ...p, message: e.target.value };
								return p;
							});
						}}
					/>
					<i className="bar" />
				</div>
				{error && error.length > 0 && (
					<div
						style={{
							width: "100%",
							display: "flex",
							flexDirection: "column",
						}}>
						<small
							style={{
								color: "tomato",
								textAlign: "left",
								marginTop: "8px",
							}}>
							{error === ["There was a problem sending your message."]
								? "There was a problem sending your message."
								: "Please fill out " +
								  error.map((err, i) => {
										if (error.length === 1) {
											return err;
										} else if (i + 1 === error.length) {
											return "and " + err;
										} else {
											return " " + err + " ";
										}
								  }) +
								  "."}
						</small>
					</div>
				)}
				{success && <small style={{ color: "green", textAlign: "left" }}>Message has been successfully sent.</small>}
				<button
					className="btn"
					onClick={(e) => {
						sendForm(e);
					}}>
					Submit Inquiry
				</button>
			</form>
		</>
	);
}
