// import CloseButton from "./CloseButton";

export default function Pricing({ setIsOpen }) {
  return (
    <div className="pricing-table-wrapper" id="pricing-wrapper">
      {/* <CloseButton func={setIsOpen} /> */}
      <div
        style={{
          width: "calc(100% - 40px)",
          height: "calc(100% - 40px)",
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ height: "100%", width: "100%", maxWidth: "1600px" }}>
          <h3 className="row-title" style={{ marginBottom: "40px" }}>
            Plans & Pricing
          </h3>
          <table className="pricing-table">
            <tbody>
              <tr>
                <td className="table-hidden" />
                <td className="centered graytd">Basic</td>
                <td className="centered graytd">Standard</td>
                <td className="centered graytd">Advanced</td>
                <td className="centered graytd">Pro</td>
                <td className="centered graytd">Enterprise</td>
              </tr>
              <tr>
                <td className="table-hidden" />
                <td className="centered">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>$</span>
                    <span
                      style={{
                        fontSize: "2rem",
                        margin: "0 4px",
                        fontWeight: 900,
                      }}
                    >
                      99
                    </span>
                    <span style={{ fontSize: "0.8rem" }}>
                      usd
                      <br />
                      /mo
                    </span>
                  </div>
                </td>
                <td className="centered">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>$</span>
                    <span
                      style={{
                        fontSize: "2rem",
                        margin: "0 4px",
                        fontWeight: 900,
                      }}
                    >
                      299
                    </span>
                    <span style={{ fontSize: "0.8rem" }}>
                      usd
                      <br />
                      /mo
                    </span>
                  </div>
                </td>
                <td className="centered">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>$</span>
                    <span
                      style={{
                        fontSize: "2rem",
                        margin: "0 4px",
                        fontWeight: 900,
                      }}
                    >
                      499
                    </span>
                    <span style={{ fontSize: "0.8rem" }}>
                      usd
                      <br />
                      /mo
                    </span>
                  </div>
                </td>
                <td className="centered">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>$</span>
                    <span
                      style={{
                        fontSize: "2rem",
                        margin: "0 4px",
                        fontWeight: 900,
                      }}
                    >
                      999
                    </span>
                    <span style={{ fontSize: "0.8rem" }}>
                      usd
                      <br />
                      /mo
                    </span>
                  </div>
                </td>
                <td className="centered">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>$</span>
                    <span
                      style={{
                        fontSize: "2rem",
                        margin: "0 4px",
                        fontWeight: 900,
                      }}
                    >
                      2999
                    </span>
                    <span style={{ fontSize: "0.8rem" }}>
                      usd
                      <br />
                      /mo
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={6} className="left graytd">
                  Features
                </td>
              </tr>
              <tr>
                <td className="left" style={{ color: "#199cdc" }}>
                  {" "}
                  Projects
                </td>
                <td>1</td>
                <td>3</td>
                <td>7</td>
                <td>15</td>
                <td>30</td>
              </tr>
              <tr>
                <td className="left">Users</td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>5</td>
                <td>10</td>
              </tr>
              <tr>
                <td className="left">Publish</td>
                <td>Queued</td>
                <td>Queued</td>
                <td>Instant</td>
                <td>Instant</td>
                <td>Instant</td>
              </tr>
              <tr>
                <td className="left">Topics to PDF, HTML, Video export</td>
                <td />
                <td />
                <td>
                  <svg
                    fill="green"
                    xmlns="http://www.w3.org/2000/svg"
                    width={27}
                    height="20.531"
                    viewBox="0 0 27 20.531"
                  >
                    <path
                      d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z"
                      transform="translate(-4.5 -7.734)"
                    />
                  </svg>
                </td>
                <td>
                  <svg
                    fill="green"
                    xmlns="http://www.w3.org/2000/svg"
                    width={27}
                    height="20.531"
                    viewBox="0 0 27 20.531"
                  >
                    <path
                      d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z"
                      transform="translate(-4.5 -7.734)"
                    />
                  </svg>
                </td>
                <td>
                  <svg
                    fill="green"
                    xmlns="http://www.w3.org/2000/svg"
                    width={27}
                    height="20.531"
                    viewBox="0 0 27 20.531"
                  >
                    <path
                      d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z"
                      transform="translate(-4.5 -7.734)"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="left">3D Rotating Product</td>
                <td />
                <td />
                <td />
                <td>
                  <svg
                    fill="green"
                    xmlns="http://www.w3.org/2000/svg"
                    width={27}
                    height="20.531"
                    viewBox="0 0 27 20.531"
                  >
                    <path
                      d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z"
                      transform="translate(-4.5 -7.734)"
                    />
                  </svg>
                </td>
                <td>
                  <svg
                    fill="green"
                    xmlns="http://www.w3.org/2000/svg"
                    width={27}
                    height="20.531"
                    viewBox="0 0 27 20.531"
                  >
                    <path
                      d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z"
                      transform="translate(-4.5 -7.734)"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="left">Custom Theme UI</td>
                <td />
                <td />
                <td />
                <td>
                  <svg
                    fill="green"
                    xmlns="http://www.w3.org/2000/svg"
                    width={27}
                    height="20.531"
                    viewBox="0 0 27 20.531"
                  >
                    <path
                      d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z"
                      transform="translate(-4.5 -7.734)"
                    />
                  </svg>
                </td>
                <td>
                  <svg
                    fill="green"
                    xmlns="http://www.w3.org/2000/svg"
                    width={27}
                    height="20.531"
                    viewBox="0 0 27 20.531"
                  >
                    <path
                      d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z"
                      transform="translate(-4.5 -7.734)"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="left">Custom Publish URL</td>
                <td />
                <td />
                <td />
                <td>
                  <svg
                    fill="green"
                    xmlns="http://www.w3.org/2000/svg"
                    width={27}
                    height="20.531"
                    viewBox="0 0 27 20.531"
                  >
                    <path
                      d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z"
                      transform="translate(-4.5 -7.734)"
                    />
                  </svg>
                </td>
                <td>
                  <svg
                    fill="green"
                    xmlns="http://www.w3.org/2000/svg"
                    width={27}
                    height="20.531"
                    viewBox="0 0 27 20.531"
                  >
                    <path
                      d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z"
                      transform="translate(-4.5 -7.734)"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="left">Output HTML Export</td>
                <td />
                <td />
                <td />
                <td />
                <td>
                  <svg
                    fill="green"
                    xmlns="http://www.w3.org/2000/svg"
                    width={27}
                    height="20.531"
                    viewBox="0 0 27 20.531"
                  >
                    <path
                      d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z"
                      transform="translate(-4.5 -7.734)"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="left">Support</td>
                <td>Ticket</td>
                <td>Ticket</td>
                <td>Full</td>
                <td>Full</td>
                <td>Priority</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
