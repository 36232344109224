export default function Flip() {
  return (
    <div style={{ color: "#023458" }} id="flip">
      <div>
        <div>
          <span style={{ color: "white" }}>An</span> Intuitive
        </div>
      </div>
      <div>
        <div>
          <span style={{ color: "white" }}>A </span>Powerful
        </div>
      </div>
      <div>
        <div>
          <span style={{ color: "white" }}>An</span> Interactive
        </div>
      </div>
      <div>
        <div>
          <span style={{ color: "white" }}>A </span>Versatile
        </div>
      </div>

      <span style={{ position: "absolute", opacity: 0 }}>An Interactive</span>
    </div>
  );
}
