import { useState } from "react";
import Carousel from "react-simply-carousel";

export default function MobileShowcase({ setIsOpen }) {
	const [activeSlide, setActiveSlide] = useState(0);

	const onChange = (value) => {
		setActiveSlide(value);
	};
	return (
		<>
			<div>
				<h3
					className="row-title"
					style={{
						height: "87px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}>
					Showcase
				</h3>
			</div>
			<div className="clients" style={{ justifyContent: "center" }}>
				<div className="clients-inner" style={{ maxWidth: "1600px", margin: 0 }}>
					<img src="./assets/images/samsung.svg" alt="" id="img-block" />
					<img src="./assets/images/lg.svg" alt="" />
					<img src="./assets/images/webostv.svg" alt="" id="demo-block" />
				</div>
			</div>
			<div className="showcase column">
				<div className="demo-row">
					<div className="demo-card">
						<a href="https://www.samsung.com/us/support/simulators/galaxy-s23/?mode=Overview" target="_blank" rel="noreferrer">
							<div id="showcase-1" />
							<p className="demo-title">S23</p>
						</a>
					</div>
					<div className="demo-card">
						<a href="https://www.samsung.com/us/support/simulators/family-hub/?mode=Overview" target="_blank" rel="noreferrer">
							<div id="showcase-2" />
							<p className="demo-title">Smart Hub Fridge</p>
						</a>
					</div>
					<div className="demo-card">
						<a href="https://www.samsung.com/us/support/simulators/smart-tv-simulator/?mode=Overview" target="_blank" rel="noreferrer">
							<div id="showcase-3" />
							<p className="demo-title">Samsung Smart TV</p>
						</a>
					</div>
					<div className="demo-card">
						<a href="https://vkrscontent.com/webostv" target="_blank" rel="noreferrer">
							<div id="showcase-4" />
							<p className="demo-title">webOStv</p>
						</a>
					</div>

					{/* <Carousel value={value} onChange={onChange}>
            <div className="demo-card">
              <a
                href="https://vicariouscontent.com/samsung/zflip3"
                target="_blank"
                rel="noreferrer"
              >
                <div id="flip3" />
                <p className="demo-title">Z Flip3</p>
              </a>
            </div>
            <div className="demo-card">
              <a
                href="https://vicariouscontent.com/samsung/zfold3"
                target="_blank"
                rel="noreferrer"
              >
                <div id="fold3" />
                <p className="demo-title">Z Fold3</p>
              </a>
            </div>
            <div className="demo-card">
              <a
                href="https://vicariouscontent.com/webostv"
                target="_blank"
                rel="noreferrer"
              >
                <div id="webos" />
                <p className="demo-title">webOStv</p>
              </a>
            </div>
          </Carousel> */}
					{/* <Dots
						value={value}
						onChange={onChange}
						thumbnails={[
							<img key={1} className="dot" src="./assets/images/dot.svg" alt="" />,
							<img key={2} className="dot" src="./assets/images/dot.svg" alt="" />,
							<img key={3} className="dot" src="./assets/images/dot.svg" alt="" />,
						]}
					/> */}
				</div>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "80px",
						width: "100%",
					}}>
					<button className="btn" style={{ width: "max-content" }} onClick={() => setIsOpen(true)}>
						View Pricing
					</button>
				</div>
			</div>
		</>
	);
}
