import { useState } from "react";
import CloseButton from "../components/CloseButton";
// import Carousel, { Dots } from "@brainhubeu/react-carousel";
// import "@brainhubeu/react-carousel/lib/style.css";
// import Collapse, { Panel } from "rc-collapse";
//import "rc-collapse/assets/index.css";

export default function MobilePricing({ setIsOpen }) {
	const [value, setValue] = useState(0);
	const handleBack = () => {
		if (value > 0) {
			setValue((p) => p - 1);
		}
	};
	const handleNext = () => {
		if (value < 4) {
			setValue((p) => p + 1);
		}
	};
	const handleClose = () => {
		setIsOpen(false);
	};
	const onChange = (value) => {
		setValue(value);
	};
	return (
		<div className="mobile-pricing-wrapper" onTouchStart={(e) => e.stopPropagation()}>
			{value === 0 ? (
				<div className="mobile-pricing-card">
					<table className="mobile-pricing-table">
						<tbody>
							<tr>
								<td colSpan={2} className="graytd left">
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "flex-start",
										}}>
										<span
											style={{
												fontSize: "1.8rem",
												margin: "0 auto 0 0",
												fontWeight: 400,
											}}>
											Basic
										</span>
										<span style={{ fontWeight: 700 }}>$</span>
										<span
											style={{
												fontSize: "2rem",
												margin: "0 4px",
												fontWeight: 900,
											}}>
											99
										</span>
										<span style={{ fontSize: "0.8rem" }}>
											usd
											<br />
											/mo
										</span>
									</div>
								</td>
							</tr>
							<tr>
								<td className="left">Projects</td>
								<td>1</td>
							</tr>
							<tr>
								<td className="left">Users</td>
								<td>1</td>
							</tr>
							<tr>
								<td className="left">Publish</td>
								<td>Queued</td>
							</tr>
							<tr>
								<td className="left">Topics to PDF, HTML, Video export</td>
								<td></td>
							</tr>
							<tr>
								<td className="left">3D Rotating Product</td>
								<td></td>
							</tr>
							<tr>
								<td className="left">Custom Theme UI</td>
								<td></td>
							</tr>
							<tr>
								<td className="left">Custom Publish URL</td>
								<td></td>
							</tr>
							<tr>
								<td className="left">Output HTML Export</td>
								<td></td>
							</tr>
							<tr>
								<td className="left">Support</td>
								<td>Ticket</td>
							</tr>
						</tbody>
					</table>
				</div>
			) : value === 1 ? (
				<div className="mobile-pricing-card">
					<table className="mobile-pricing-table">
						<tbody>
							<tr>
								<td colSpan={2} className="graytd left">
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "flex-start",
										}}>
										<span
											style={{
												fontSize: "1.8rem",
												margin: "0 auto 0 0",
												fontWeight: 400,
											}}>
											Standard
										</span>
										<span style={{ fontWeight: 700 }}>$</span>
										<span
											style={{
												fontSize: "2rem",
												margin: "0 4px",
												fontWeight: 900,
											}}>
											299
										</span>
										<span style={{ fontSize: "0.8rem" }}>
											usd
											<br />
											/mo
										</span>
									</div>
								</td>
							</tr>
							<tr>
								<td className="left">Projects</td>
								<td>3</td>
							</tr>
							<tr>
								<td className="left">Users</td>
								<td>2</td>
							</tr>
							<tr>
								<td className="left">Publish</td>
								<td>Queued</td>
							</tr>
							<tr>
								<td className="left">Topics to PDF, HTML, Video export</td>
								<td></td>
							</tr>
							<tr>
								<td className="left">3D Rotating Product</td>
								<td></td>
							</tr>
							<tr>
								<td className="left">Custom Theme UI</td>
								<td></td>
							</tr>
							<tr>
								<td className="left">Custom Publish URL</td>
								<td></td>
							</tr>
							<tr>
								<td className="left">Output HTML Export</td>
								<td></td>
							</tr>
							<tr>
								<td className="left">Support</td>
								<td>Ticket</td>
							</tr>
						</tbody>
					</table>
				</div>
			) : value === 2 ? (
				<div className="mobile-pricing-card">
					<table className="mobile-pricing-table">
						<tbody>
							<tr>
								<td colSpan={2} className="graytd left">
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "flex-start",
										}}>
										<span
											style={{
												fontSize: "1.8rem",
												margin: "0 auto 0 0",
												fontWeight: 400,
											}}>
											Advanced
										</span>
										<span style={{ fontWeight: 700 }}>$</span>
										<span
											style={{
												fontSize: "2rem",
												margin: "0 4px",
												fontWeight: 900,
											}}>
											499
										</span>
										<span style={{ fontSize: "0.8rem" }}>
											usd
											<br />
											/mo
										</span>
									</div>
								</td>
							</tr>
							<tr>
								<td className="left">Projects</td>
								<td>7</td>
							</tr>
							<tr>
								<td className="left">Users</td>
								<td>3</td>
							</tr>
							<tr>
								<td className="left">Publish</td>
								<td>Instant</td>
							</tr>
							<tr>
								<td className="left">Topics to PDF, HTML, Video export</td>
								<td>
									{" "}
									<svg fill="green" xmlns="http://www.w3.org/2000/svg" width={22} height="10" viewBox="0 0 27 20.531">
										<path d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z" transform="translate(-4.5 -7.734)" />
									</svg>
								</td>
							</tr>
							<tr>
								<td className="left">3D Rotating Product</td>
								<td></td>
							</tr>
							<tr>
								<td className="left">Custom Theme UI</td>
								<td></td>
							</tr>
							<tr>
								<td className="left">Custom Publish URL</td>
								<td></td>
							</tr>
							<tr>
								<td className="left">Output HTML Export</td>
								<td></td>
							</tr>
							<tr>
								<td className="left">Support</td>
								<td>Full</td>
							</tr>
						</tbody>
					</table>
				</div>
			) : value === 3 ? (
				<div className="mobile-pricing-card">
					<table className="mobile-pricing-table">
						<tbody>
							<tr>
								<td colSpan={2} className="graytd left">
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "flex-start",
										}}>
										<span
											style={{
												fontSize: "1.8rem",
												margin: "0 auto 0 0",
												fontWeight: 400,
											}}>
											Pro
										</span>
										<span style={{ fontWeight: 700 }}>$</span>
										<span
											style={{
												fontSize: "2rem",
												margin: "0 4px",
												fontWeight: 900,
											}}>
											999
										</span>
										<span style={{ fontSize: "0.8rem" }}>
											usd
											<br />
											/mo
										</span>
									</div>
								</td>
							</tr>
							<tr>
								<td className="left">Projects</td>
								<td>15</td>
							</tr>
							<tr>
								<td className="left">Users</td>
								<td>5</td>
							</tr>
							<tr>
								<td className="left">Publish</td>
								<td>Instant</td>
							</tr>
							<tr>
								<td className="left">Topics to PDF, HTML, Video export</td>
								<td>
									{" "}
									<svg fill="green" xmlns="http://www.w3.org/2000/svg" width={22} height="10" viewBox="0 0 27 20.531">
										<path d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z" transform="translate(-4.5 -7.734)" />
									</svg>
								</td>
							</tr>
							<tr>
								<td className="left">3D Rotating Product</td>
								<td>
									{" "}
									<svg fill="green" xmlns="http://www.w3.org/2000/svg" width={22} height="10" viewBox="0 0 27 20.531">
										<path d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z" transform="translate(-4.5 -7.734)" />
									</svg>
								</td>
							</tr>
							<tr>
								<td className="left">Custom Theme UI</td>
								<td>
									{" "}
									<svg fill="green" xmlns="http://www.w3.org/2000/svg" width={22} height="10" viewBox="0 0 27 20.531">
										<path d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z" transform="translate(-4.5 -7.734)" />
									</svg>
								</td>
							</tr>
							<tr>
								<td className="left">Custom Publish URL</td>
								<td>
									{" "}
									<svg fill="green" xmlns="http://www.w3.org/2000/svg" width={22} height="10" viewBox="0 0 27 20.531">
										<path d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z" transform="translate(-4.5 -7.734)" />
									</svg>
								</td>
							</tr>
							<tr>
								<td className="left">Output HTML Export</td>
								<td></td>
							</tr>
							<tr>
								<td className="left">Support</td>
								<td>Full</td>
							</tr>
						</tbody>
					</table>
				</div>
			) : value === 4 ? (
				<div className="mobile-pricing-card">
					<table className="mobile-pricing-table">
						<tbody>
							<tr>
								<td colSpan={2} className="graytd left">
									<div
										style={{
											display: "flex",
											justifyContent: "center",
											alignItems: "flex-start",
										}}>
										<span
											style={{
												fontSize: "1.8rem",
												margin: "0 auto 0 0",
												fontWeight: 400,
											}}>
											Enterprise
										</span>
										<span style={{ fontWeight: 700 }}>$</span>
										<span
											style={{
												fontSize: "2rem",
												margin: "0 4px",
												fontWeight: 900,
											}}>
											2999
										</span>
										<span style={{ fontSize: "0.8rem" }}>
											usd
											<br />
											/mo
										</span>
									</div>
								</td>
							</tr>
							<tr>
								<td className="left">Projects</td>
								<td>30</td>
							</tr>
							<tr>
								<td className="left">Users</td>
								<td>10</td>
							</tr>
							<tr>
								<td className="left">Publish</td>
								<td>Instant</td>
							</tr>
							<tr>
								<td className="left">Topics to PDF, HTML, Video export</td>
								<td>
									{" "}
									<svg fill="green" xmlns="http://www.w3.org/2000/svg" width={22} height="10" viewBox="0 0 27 20.531">
										<path d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z" transform="translate(-4.5 -7.734)" />
									</svg>
								</td>
							</tr>
							<tr>
								<td className="left">3D Rotating Product</td>
								<td>
									{" "}
									<svg fill="green" xmlns="http://www.w3.org/2000/svg" width={22} height="10" viewBox="0 0 27 20.531">
										<path d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z" transform="translate(-4.5 -7.734)" />
									</svg>
								</td>
							</tr>
							<tr>
								<td className="left">Custom Theme UI</td>
								<td>
									{" "}
									<svg fill="green" xmlns="http://www.w3.org/2000/svg" width={22} height="10" viewBox="0 0 27 20.531">
										<path d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z" transform="translate(-4.5 -7.734)" />
									</svg>
								</td>
							</tr>
							<tr>
								<td className="left">Custom Publish URL</td>
								<td>
									<svg fill="green" xmlns="http://www.w3.org/2000/svg" width={22} height="10" viewBox="0 0 27 20.531">
										<path d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z" transform="translate(-4.5 -7.734)" />
									</svg>
								</td>
							</tr>
							<tr>
								<td className="left">Output HTML Export</td>
								<td>
									<svg fill="green" xmlns="http://www.w3.org/2000/svg" width={22} height="10" viewBox="0 0 27 20.531">
										<path d="M13.1,23.9,6.75,17.54,4.5,19.685l8.6,8.58L31.5,9.879,29.25,7.734Z" transform="translate(-4.5 -7.734)" />
									</svg>
								</td>
							</tr>
							<tr>
								<td className="left">Support</td>
								<td>Priority</td>
							</tr>
						</tbody>
					</table>
				</div>
			) : null}
			{/* <Carousel value={value} onChange={onChange}>

      </Carousel>
      <Dots
        value={value}
        onChange={onChange}
        thumbnails={[
          <img key={1} className="dot" src="./assets/images/dot.svg" alt="" />,
          <img key={2} className="dot" src="./assets/images/dot.svg" alt="" />,
          <img key={3} className="dot" src="./assets/images/dot.svg" alt="" />,
          <img key={4} className="dot" src="./assets/images/dot.svg" alt="" />,
          <img key={5} className="dot" src="./assets/images/dot.svg" alt="" />,
        ]}
      /> */}
			<div
				style={{
					display: "flex",
					flex: 2,
					justifyContent: "space-between",
					alignItems: "center",
					margin: "20px 0",
					gap: "1rem",
					width: 400,
					maxWidth: "100%",
					margin: "20px auto",
				}}>
				<button className={`mobile-pricing-btn${value === 0 ? " disabled" : ""}`} onClick={handleBack}>
					back
				</button>
				<button className={`mobile-pricing-btn${value === 4 ? " disabled" : ""}`} onClick={handleNext}>
					next
				</button>
				<button className={`mobile-pricing-btn`} onClick={handleClose} style={{ backgroundColor: "transparent" }}>
					<svg fill={"black"} xmlns="http://www.w3.org/2000/svg" width="20.953" height="20.953" viewBox="0 0 20.953 20.953">
						<path d="M28.477,9.619l-2.1-2.1L18,15.9,9.619,7.523l-2.1,2.1L15.9,18,7.523,26.381l2.1,2.1L18,20.1l8.381,8.381,2.1-2.1L20.1,18Z" transform="translate(-7.523 -7.523)" />
					</svg>
				</button>
			</div>
		</div>
	);
}
