import React from "react";

export default function AboutUs({ func }) {
	return (
		<div
			style={{
				width: "700px",
				maxWidth: "90%",
				margin: "0 auto",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				textAlign: "justify",
			}}>
			<p className="about-us">
				VKRS provides advertising, marketing, web management, and hosting services. Our online interactive tools are designed to help create websites that are optimized for
				customer accessibility and navigation. Live support is available to assist you in maintaining and improving the websites you create through our program. Please contact us
				to begin a consultation today.
			</p>
			<button className="btn hero-button" style={{ margin: "20px 0" }} onClick={() => func.moveTo(8)}>
				Contact Us
			</button>
		</div>
	);
}
