import { useEffect, useState } from "react";
export default function Navigation({ setIsOpen, isOpen, setFullOpen, func, d, setMobileMenu }) {
	const [hovering, setHovering] = useState(false);
	// useEffect(() => {
	//   const target = document.querySelector(".target");
	//   const links = document.querySelectorAll(".nav-items p");
	//   const active = document.querySelector(".nav-accent");
	//   if (active && !hovering) {
	//     const left = active.getBoundingClientRect().left + window.pageXOffset;
	//     const top = active.getBoundingClientRect().top + window.pageYOffset;
	//     const width = active.getBoundingClientRect().width;
	//     const height = active.getBoundingClientRect().height;
	//     target.style.width = `${width}px`;
	//     target.style.height = `${height}px`;
	//     target.style.left = `${left}px`;
	//     target.style.top = `${top}px`;
	//     target.style.transform = "none";
	//   }

	//   function mouseenterFunc() {
	//     if (!this.classList.contains("nav-accent")) {
	//       for (let i = 0; i < links.length; i++) {
	//         if (links[i].classList.contains("nav-accent")) {
	//           links[i].classList.remove("nav-accent");
	//         }
	//         links[i].style.opacity = "0.75";
	//       }

	//       this.classList.add("nav-accent");
	//       this.style.opacity = "1";

	//       const width = this.getBoundingClientRect().width;
	//       const height = this.getBoundingClientRect().height;
	//       const left = this.getBoundingClientRect().left + window.pageXOffset;
	//       const top = this.getBoundingClientRect().top + window.pageYOffset;

	//       target.style.width = `${width}px`;
	//       target.style.height = `${height}px`;
	//       target.style.left = `${left}px`;
	//       target.style.top = `${top}px`;
	//       target.style.transform = "none";
	//     }
	//   }

	//   if (hovering) {
	//     for (let i = 0; i < links.length; i++) {
	//       links[i].addEventListener("click", (e) => e.preventDefault());
	//       links[i].addEventListener("mouseenter", mouseenterFunc);
	//     }
	//   } else if (!hovering) {
	//     for (let i = 0; i < links.length; i++) {
	//       links[i].removeEventListener("click", (e) => e.preventDefault());
	//       links[i].removeEventListener("mouseenter", mouseenterFunc);
	//       if (d < 4 && !links[0].classList.contains("nav-accent")) {
	//         links[0].classList.add("nav-accent");
	//         links[0].style.opacity = "1";
	//         const width = links[0].getBoundingClientRect().width;
	//         const height = links[0].getBoundingClientRect().height;
	//         const left =
	//           links[0].getBoundingClientRect().left + window.pageXOffset;
	//         const top = links[0].getBoundingClientRect().top + window.pageYOffset;
	//         target.style.width = `${width}px`;
	//         target.style.height = `${height}px`;
	//         target.style.left = `${left}px`;
	//         target.style.top = `${top}px`;
	//         target.style.transform = "none";
	//       }
	//       if (d === 4) {
	//         links[3].classList.add("nav-accent");
	//       }
	//       if (i === 0 && d > 5) {
	//         links[i].classList.remove("nav-accent");
	//       } else if (d === i && !links[i].classList.contains("nav-accent")) {
	//         links[i].classList.add("nav-accent");
	//       }
	//     }
	//   }

	//   function resizeFunc() {
	//     if (active) {
	//       const left = active.getBoundingClientRect().left + window.pageXOffset;
	//       const top = active.getBoundingClientRect().top + window.pageYOffset;

	//       target.style.left = `${left}px`;
	//       target.style.top = `${top}px`;
	//     }
	//   }
	//   window.addEventListener("resize", resizeFunc);
	//   return () => {
	//     window.removeEventListener("resize", resizeFunc);
	//   };
	// }, [hovering, d]);
	return (
		<>
			<div className="menu-navigation" style={d > 0 && d !== 5 && d !== 5 ? { backgroundColor: "#199cdc" } : {}}>
				<div className="flex-nav">
					{(d === 0 || d === 5) && <img src="../assets/images/vkrslogo.svg" className="logo fadein" alt="Vikarius logo" />}

					{d !== 0 && d !== 5 && <img src="../assets/images/vkrslogo-white.svg" className="logo fadein" alt="Vikarius logo" />}

					<div
						className="nav-items"
						onMouseEnter={() => {
							setHovering(true);
						}}
						onMouseLeave={() => {
							setHovering(false);
						}}>
						<p
							className={(d === 0 || d === 5) && !isOpen ? "nav-accent-alt" : d < 6 && !isOpen ? "nav-accent" : ""}
							style={d > 0 && d !== 5 ? { color: "white" } : {}}
							onClick={() => func.moveTo(1)}>
							Home
						</p>
						<p
							className={(d === 0 || d === 5) && isOpen ? "nav-accent-alt" : isOpen ? "nav-accent" : ""}
							style={d > 0 && d !== 5 ? { color: "white" } : {}}
							onClick={() => {
								setIsOpen(true);
							}}>
							Pricing
						</p>
						<p className={d === 6 && !isOpen ? "nav-accent" : ""} style={d > 0 && d !== 5 ? { color: "white" } : {}} onClick={() => func.moveTo(7)}>
							Showcase
						</p>
						<p className={d === 7 && !isOpen ? "nav-accent" : ""} style={d > 0 && d !== 5 ? { color: "white" } : {}} onClick={() => func.moveTo(8)}>
							Contact
						</p>

						<p
							style={d > 0 && d !== 5 ? { color: "white" } : {}}
							onClick={() => {
								window.location = "https://vkrscontent.com/login";
							}}>
							Sign in
						</p>
					</div>
				</div>
				{/* <div class="target">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={15}
            height="9.545"
            viewBox="0 0 15 9.545"
          >
            <defs>
              <style
                dangerouslySetInnerHTML={{
                  __html: ".a{fill:#023458;}",
                }}
              />
            </defs>
            <path
              className="a"
              d="M7.5,0,15,9.545H0Z"
              transform="translate(15 9.545) rotate(180)"
            />
          </svg>
        </div> */}
				<div className="menu-bg" id="navigation"></div>
				<svg
					onClick={() => {
						setMobileMenu(true);
					}}
					className="menu-icon"
					xmlns="http://www.w3.org/2000/svg"
					width="27"
					height="18"
					viewBox="0 0 27 18">
					<path d="M4.5,27h27V24H4.5Zm0-7.5h27v-3H4.5ZM4.5,9v3h27V9Z" transform="translate(-4.5 -9)" />
				</svg>
			</div>
		</>
	);
}
