import React from "react";

export default function CloseButton({ func, color }) {
	return (
		<svg
			onClick={() => {
				func(false);
			}}
			className="closeModal"
			fill={color}
			xmlns="http://www.w3.org/2000/svg"
			width="20.953"
			height="20.953"
			viewBox="0 0 20.953 20.953">
			<path
				d="M28.477,9.619l-2.1-2.1L18,15.9,9.619,7.523l-2.1,2.1L15.9,18,7.523,26.381l2.1,2.1L18,20.1l8.381,8.381,2.1-2.1L20.1,18Z"
				transform="translate(-7.523 -7.523)"
			/>
		</svg>
	);
}
